
#footer {
  //background: var(--color-primary);
  background: #353639;
  position: relative;
  margin-top: 100px;
  padding-top: 0;

  a {
    color: #000;
  }

  .custom-footer {
    padding: 0 0 50px 0;

    h4,.h4title {
      color: #fff;
      font-size: 14px;
      margin-bottom: 15px;
      margin-top:11px
    }

    a {
      color: #949498;
    }

    ul {
      margin-left: 5px;

      li span:before {
        color: #949498;
      }
    }

    .news-item-widget {
      margin-left: 5px;
      padding: 2px 0;

      h5,.h5title {
        font-size: 14px;
        padding: 4px 0;
        font-weight: 300 !important;
        line-height: 1.1;
        word-wrap: break-word;
        text-transform: none;
      }
    }

    @media (min-width: 769px) {

      & > div {
        padding-bottom: 0;
      }
    }
    &.elements-many > div:nth-child(4n+1){
      clear: none !important;
    }
  }

  .custom-footer__newsletter {
    width: 100%;
    margin-bottom: 50px;
    color: #fff;
    padding: 50px 0 !important;
    border-bottom: 1px solid #424244;
    display: flex;
    align-items: center;

    & > div, & > label {
      flex: 1;

    }

    & > form {
      flex: 2;

      .consents.consents-first {
        display: none;
      }

      padding-right: 30px;
    }

    br {
      display: none;
    }

    .topic{
      display:none;
    }
    .h4title {
      color: #fff;
      font-size: 24px;
      font-weight: 300;
      margin-top: 11px;
    }

    .btn-default {
      background: var(--color-primary);
      border-color: var(--color-primary);

    }

    .whole-width {
      font-size: 13px;
      padding-left: 30px;

      a {
        color: var(--color-primary);
      }
    }

    @media (max-width: 768px) {
      display: inline-block;
      width:90%;
      margin-left:5%;
      .whole-width{
        padding-left: 0;
      }
    }

  }

  .footer-bottom {
    background: #353639;
    border-top: 1px solid #424244;
    font-size: 13px;
    color: #666;
    padding: 20px 0;

    a {
      color: #666;

      &:hover {
        color: #fff;
      }
    }
  }

  .footer-links-icons{
    border-top: 1px solid #424244;
    .footer-icon {
      margin:0 20px;
      img {
        height: 50px;
      }
    }
  }
}

//Newsletter
.subscribe-form {
  .btn.btn-arrow-right {
    padding: 5px 10px 5px 12px;

    &:before {
      height: 30px;
      width: 20px;
      content: '';
      background-size: 100%;
      border-radius: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }
}

#footer .custom-footer .news-item-widget {
  margin-left: 0;
}

#footer .custom-footer ul {
  margin-left: 0;

  .form {
    margin-bottom: 10px;

    &:before {
      content: '';
      color: #949498;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23949498' d='M20 2C20.5523 2 21 2.44772 21 3V6.757L19 8.757V4H5V20H19V17.242L21 15.242V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761ZM13 12V14H8V12H13ZM16 8V10H8V8H16Z'%3E%3C/path%3E%3C/svg%3E");
      height: 30px;
      width: 30px;
      margin-right: 15px;
      margin-left: 0;
    }

    a {
      color: $color-orange;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .mail {
    margin-bottom: 6px;

    &:before {
      content: '';
      color: #949498;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23949498' d='M2.24283 6.85419L11.4895 1.30843C11.8062 1.11848 12.2019 1.11855 12.5185 1.30862L21.7573 6.85416C21.9079 6.94453 22 7.10726 22 7.28286V19.9998C22 20.5521 21.5523 20.9998 21 20.9998H3C2.44772 20.9998 2 20.5521 2 19.9998V7.28298C2 7.10732 2.09218 6.94454 2.24283 6.85419ZM4 8.13244V18.9998H20V8.13197L12.0037 3.33221L4 8.13244ZM12.0597 13.6981L17.3556 9.23515L18.6444 10.7645L12.074 16.3016L5.36401 10.7715L6.63599 9.22813L12.0597 13.6981Z'%3E%3C/path%3E%3C/svg%3E");
      height: 30px;
      width: 30px;
      margin-right: 10px;
      margin-left: 0;
    }

    a {
      color: $color-orange;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .tel {
    margin-bottom: 6px;

    &:before {
      content: '';
      color: #949498;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23949498' d='M9.36556 10.6821C10.302 12.3288 11.6712 13.698 13.3179 14.6344L14.2024 13.3961C14.4965 12.9845 15.0516 12.8573 15.4956 13.0998C16.9024 13.8683 18.4571 14.3353 20.0789 14.4637C20.599 14.5049 21 14.9389 21 15.4606V19.9234C21 20.4361 20.6122 20.8657 20.1022 20.9181C19.5723 20.9726 19.0377 21 18.5 21C9.93959 21 3 14.0604 3 5.5C3 4.96227 3.02742 4.42771 3.08189 3.89776C3.1343 3.38775 3.56394 3 4.07665 3H8.53942C9.0611 3 9.49513 3.40104 9.5363 3.92109C9.66467 5.54288 10.1317 7.09764 10.9002 8.50444C11.1427 8.9484 11.0155 9.50354 10.6039 9.79757L9.36556 10.6821ZM6.84425 10.0252L8.7442 8.66809C8.20547 7.50514 7.83628 6.27183 7.64727 5H5.00907C5.00303 5.16632 5 5.333 5 5.5C5 12.9558 11.0442 19 18.5 19C18.667 19 18.8337 18.997 19 18.9909V16.3527C17.7282 16.1637 16.4949 15.7945 15.3319 15.2558L13.9748 17.1558C13.4258 16.9425 12.8956 16.6915 12.3874 16.4061L12.3293 16.373C10.3697 15.2587 8.74134 13.6303 7.627 11.6707L7.59394 11.6126C7.30849 11.1044 7.05754 10.5742 6.84425 10.0252Z'%3E%3C/path%3E%3C/svg%3E");
      height: 30px;
      width: 30px;
      margin-right: 10px;
      margin-left: 0;
    }

    a {
      font-size: 24px;
      color: #fff;

      &:hover {
        text-decoration: none;
        color: $color-orange;
      }
    }
  }
}

.footer-contact {
  color: $color-grey-text;
  line-height: 1.5;
}

.footer-bottom {
  .copyright {
    position: relative;
    padding-left: 30px;
  }
  .footer-able {
    position: absolute;
    left: 0;
    height: 18px;
    width: auto;
  }
  @media (max-width: 768px) {
  .copyright{
    margin:20px 0;
    padding-left: 0;
    display: inline-block;
    img{
      margin: 20px auto;
      display: flex;
      height: 26px;
      position: relative;
    }
  }
  }
}

#footer .custom-footer__newsletter .newsletter-header {
  flex: 2;
}
@media (max-width: 1440px) {
  #footer .custom-footer__newsletter .newsletter-header .h4title{
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  #footer .custom-footer__newsletter .newsletter-header .h4title{
    font-size: 17px;
  }
  .custom-footer > div:nth-child(2n+1) {
    min-height: 250px;
    clear: none !important;
    //background-color: #F90;
  }
}
@media (max-width: 768px) {
  .custom-footer > div:nth-child(2n+1) {
    min-height: 100px;
    clear: none !important;
    //background-color: #F90;
  }
}