body.type-manufacturers-listing {
    .category-title {
        font-weight: 500;
    }

    ul.manufacturers-anchors {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;

        li {
            flex: 1;
            text-align: center;

            a {
                font-size: 21px;
                text-decoration: none;
            }

            &:hover {
                background: #fff;
            }
        }
    }

    ul.manufacturers {
        li {
            &:hover {
                background: #fff;
            }
        }
    }
}

.manufacturer-banners {
    padding: 20px 0 60px 0;
    position: relative;
    background-color: $color-light-grey;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        left: -9998px;
        right: 0;
        border-left: 9999px solid #f7f7f7;
        box-shadow: 9999px 0 0 #f7f7f7;
        display: flex;
    }

    .h4 {
        margin-bottom: 30px;
        width: 100%;
    }

    .banner {
        margin-bottom: 0;
        @include tablet-portrait {
            min-height: auto;
        }

    }

    .banner-wrapper {
        overflow: hidden;

        img {
            transition: all .2s;
            filter: grayscale(1);
        }

        &:hover {
            img {
                transform: scale(1.05);
                filter: grayscale(0);
            }
        }
    }
}

.manufacturer-button {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}

.manufacturer-overlay {
    max-height: 300px;
    overflow: hidden;
    position: relative;
    transition: all .2s linear;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 220px;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7);
        transition: all .3s linear;
    }

    &.active {
        max-height: 4000px;

        &:after {
            content: none;
        }
    }
}

.manufacturer-more, .manufacturer-less {
    display: block;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    margin: 5px 0;

    &:hover {
        text-decoration: none;
    }
}

.manufacturer-less {
    display: none;

    &.active {
        display: block;
    }
}

.manufacturer-detail {
    text-align: center;

    .manufacturer-image {
        margin-bottom: 50px;
        img {
            width: 100%;
            max-width: 250px;
            height: auto;
        }
    }
}

ul.manufacturers > li li a {
    &:hover {
        color: $color-orange;
    }
}

.type-post, .type-manufacturers-listing {
    .content-wrapper-in {
        .sidebar {
            display: none;
        }
    }
}

.manufacturer-detail {
    max-width: 1178px;
    margin-left: auto;
    margin-right: auto;
}

/*.loaded.in-znacka {
    #content,
    .sidebar {
        opacity: 1;
    }
}*/

.in-znacka {
    .sidebar-inner {
        position: relative;
        z-index: 999;
    }
}

.manufacturer-top-block {

}

ul.manufacturers > li a[data-testid="brandLink"] {
    text-decoration: none;
}

.manufacturer-desc {
    position: relative;
    margin: -40px auto -20px;
    background-color: #fff;
    text-align: center;
    width: 80%;
    padding: 40px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
    z-index: 4;
    .manufacturer-overlay {
        max-height: 70px;
        overflow: hidden;
        position: relative;
        transition: all .2s linear;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
            transition: all .3s linear;
        }

        &.active {
            max-height: 4000px;

            &:after {
                content: none;
            }
        }
    }

    .manufacturer-more,
    .manufacturer-less {
        display: block;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        margin: 5px 0;

        &:hover {
            text-decoration: none;
        }
    }

    .manufacturer-less {
        display: none;

        &.active {
            display: block;
        }
    }
}

