/*
DROBEČKOVÁ NAVIGACE
*/

.breadcrumbs {
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
  .navigation-bullet {
    margin: 0 10px
  }

  a,span a {
    text-decoration: none;

    &:hover {
      &:before, span {
        color: #000;
      }
    }
  }
}

@media (min-width: 992px) {
  .type-product .breadcrumbs {
    margin-left: unset;
    width: 100%;
    padding-left: 0;
    padding-bottom: 0;
  }
}
.aside-indented .breadcrumbs {
  padding-left: 20px;
  padding-right: 20px;
  max-width: unset;
}
