.siteCookies {
  .siteCookies__buttonWrap {
    .siteCookies__button[value=reject] {
      background-color: #000;
      border: 2px solid #000;

      &:hover {
        background-color: transparent;
        border: 2px solid #000;
        color: #000;
      }
    }

    .siteCookies__button[value=all] {
      background-color: $color-orange;
      border: 2px solid $color-orange;

      &:hover {
        border: 2px solid $color-orange;
        background-color: transparent;
        color: $color-orange;
      }
    }
  }

  .siteCookies__links {
    .siteCookies__link {
      border: 2px solid #000;
      background-color: transparent;

      &:hover {
        background-color: #000;
      }
    }
  }
}