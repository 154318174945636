/*
  Slider
*/
.product-slider-holder {
  padding-top: 0;

  .product-slider-pagination .pagination-button {
    height: 5px;
    border-radius: 3px;
  }

  .product-slider-navigation {
    height: 45px;

    &.navigation-next::before, &.navigation-prev::before {
      font-size: 45px;
      background: transparent !important;
    }
  }

  .products-block {
    padding: 20px 0;
  }

  .products-block > div .p .p-bottom.no-buttons > div .p-tools {
    display: inline-block;
  }
}


