.in-znacka {
    #products {
        //background-color: $color-light-grey;
        //box-shadow: 0 0 0 100vw #f7f7f7;
        //clip-path: inset(0 -100vw);
        //padding: 50px 15px;
    }

    .category-title {
        display: none;
    }
}

.brand-wrapper {
    display: flex;
    align-items: center;
    color: $color-grey;

    @include tablet-portrait {
    //    flex-direction: column;
    }

    .manufacturer-image {
        @include tablet-portrait {
            margin-top: 10px;
        }

        img {
            width: 120px;
            height: auto;
        }
    }
}