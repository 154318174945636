body.id--12,body.id--18
{
  #content{
    .table{
      th:last-of-type{display:none;}
      td:last-of-type{display:none;}
    }
  }
}

.client-center-box{
  li:nth-of-type(6),li:nth-of-type(7),li:nth-of-type(8),li:nth-of-type(9){
    display:none;
  }
}

body.id--18{
  h2 a{
    width:40px;
    display: inline-block;
    text-align: center;
    margin-left: 40px;
    img{
      height: 30px;
    }
  }
}