.shoptet-modal-lg.productDetail {
    display: none !important;
}

.lg-icon {
    font-family: 'lg';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lg-next,
.lg-prev {
    cursor: pointer;
    display: none;

    @media (max-width: 1023px) {
        display: block;
        top: auto;
        bottom: 0;
        width: 21% !important;
        height: 100px !important;
        background-color: transparent !important;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../images/svg/right.svg');
            background-size: 74px auto;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:after {
            display: none;
        }

    }

}

.lg-prev {
    @media (max-width: 1023px) {
        &:before {
            transform: rotate(180deg);
        }
    }
}


.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: .5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #fff;
}

.lg-actions .lg-next:before, .lg-actions .lg-prev:before {
    content: '';
    width: 100% !important;
    height: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    //background-image: url(/assets/frontend/img/carousel/right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.lg-actions .lg-prev:before {
    //background-image: url(/assets/frontend/img/carousel/left.svg)
}

.lg-outer.lg-right-end .lg-object {
    -webkit-animation: lg-right-end .3s;
    animation: lg-right-end .3s;
    position: relative;
}

.lg-outer.lg-left-end .lg-object {
    -webkit-animation: lg-left-end .3s;
    animation: lg-left-end .3s;
    position: relative;
}

.lg-toolbar {
    z-index: 1082;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: auto;
    background: $color-orange;
}

.lg-toolbar .lg-icon {
    color: #fff;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 50px;
    line-height: 43px;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}

.lg-toolbar {
    .lg-close {
        background-color: $color-orange;
        background-image: url('../../images/svg/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px auto;
        transition: background-color 0.2s;

        &:hover {
            background-color: #e66406;
        }

        &:before,
        &:after {
            display: none;
        }
    }

    .lg-counter,
    .lg-download,
    .lg-fullscreen {
        display: none;
    }

}



.lg-sub-html {
    background-color: #fff;
    bottom: 40px;
    font-family: "Montserrat", Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #24282b;
    left: 0;
    right: 0;
    padding: 10px 40px;
    position: absolute;
    text-align: center;
    z-index: 1080;
}

.lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 700;
}

.lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0;
}

#lg-counter {
    color: #999;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
    opacity: 0;
    -webkit-transition: opacity 250ms cubic-bezier(0, 0, .25, 1), -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1), -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s !important;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
}

.lg-content .lg-thumb-outer {
    background-color: #fff;
    padding-top: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 250px;
    max-height: 100% !important;
    height: 100%;
    z-index: 1080;
    //transform: translate3d(250px, 0, 0);
    transition: transform .25s cubic-bezier(0, 0, .25, 1) 0s;
    display: none;
}

.lg-content .lg-thumb-outer:before {
    content: "";
    position: absolute;
    left: 15px;
    top: -50px;
    bottom: 0;
    width: 1px;
    background: #efeff2;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
    -webkit-transform: translate3d(0, 0%, 0);
    transform: translate3d(0, 0%, 0);
}


.lg-content .lg-thumb-item {
    cursor: pointer !important;
    margin: 0 0 20px !important;
    width: auto !important;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.lg-content .lg-thumb-item img {
    max-width: 200px;
    display: block;
    margin: 0 auto;
    align-self: center;
    max-height: 200px
}

@media (max-width: 1023px) {
    .lg-outer.lg-has-thumb .lg-item {
            padding-bottom: 120px
    }
}


.lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0
}

.lg-outer .lg-toogle-thumb {
    background-color: #0d0a0a;
    border-radius: 2px 2px 0 0;
    color: #999;
    cursor: pointer;
    font-size: 24px;
    height: 39px;
    line-height: 27px;
    padding: 5px 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -39px;
    width: 50px;
    display: none
}

.lg-content .lg-thumb {
    padding: 0 0 0 8px;
    height: 100%;
    margin-bottom: -5px;
    width: auto !important;
}

.lg-content .lg-thumb-item {
    cursor: pointer;
    margin: 0 0 20px !important;
    width: auto !important;
    position: relative;
    display: flex;
}

.lg-content .lg-thumb-item.active:before {
    content: "";
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    background: $color-orange;
    width: 2px;
}

.lg-content .lg-thumb-item.active:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border: 2px solid $color-orange;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(-45deg);
    left: 2px;
    top: 50%;
    margin-top: -4px;
}

.lg-content .lg-thumb-item {

    &.active,
    &:hover {
        border-color: transparent;
    }

    img {
        max-width: 200px;
        display: block;
        margin: 0 auto;
        align-self: center;
        max-height: 200px;
    }

    &.is-360 {
        background-image: url('../../img/360-degree-rotate-icon.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 50px auto;
    }
}


.lg-outer.lg-has-thumb .lg-item {
    //padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0;
}

.lg-outer .lg-toogle-thumb {
    background-color: #0d0a0a;
    border-radius: 2px 2px 0 0;
    color: #999;
    cursor: pointer;
    font-size: 24px;
    height: 39px;
    line-height: 27px;
    padding: 5px 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -39px;
    width: 50px;
    display: none;
}

.lg-outer .lg-toogle-thumb:after {
    content: "";
}

.lg-outer .lg-toogle-thumb:hover {
    color: #fff
}

.lg-outer .lg-video-cont {
    display: inline-block;
    vertical-align: middle;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 0 5px;
}

.lg-outer .lg-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
}

.lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer;
}

.lg-outer .lg-has-vimeo .lg-video-play {
    //background: url(/assets/frontend/img/vimeo-play.png) no-repeat 0 0
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
    //background: url(/assets/frontend/img/vimeo-play.png) no-repeat 0 -58px
}

.lg-outer .lg-has-html5 .lg-video-play {
    //background: url(/assets/frontend/img/video-play.png) no-repeat 0 0;
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    opacity: .8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
    opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
    //background: url(/assets/frontend/img/youtube-play.png) no-repeat 0 0
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
    //background: url(/assets/frontend/img/youtube-play.png) no-repeat 0 -60px
}

.lg-outer .lg-video-object {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
    visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
    display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
    visibility: visible;
}

.lg-progress-bar {
    background-color: #333;
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1080;
    opacity: 0;
    transition: opacity .08s ease 0s
}

.lg-progress-bar.lg-start .lg-progress {
    width: 100%
}

.lg-show-autoplay .lg-progress-bar {
    opacity: 1;
}

.lg-autoplay-button:after {
    content: ""
}

.lg-show-autoplay .lg-autoplay-button:after {
    content: ""
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: opacity .15s, -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
    transition: transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s, -webkit-transform .3s cubic-bezier(0, 0, .25, 1) 0s !important;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

#lg-zoom-in:after {
    content: ""
}

#lg-actual-size {
    font-size: 20px
}

#lg-actual-size:after {
    content: ""
}

#lg-zoom-out {
    opacity: .5;
    pointer-events: none
}

#lg-zoom-out:after {
    content: ""
}

.lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto
}

.lg-outer .lg-pager-outer {
    bottom: 60px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1080;
    height: 10px
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible
}

.lg-outer .lg-pager-cont {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    margin: 0 5px
}

.js .home-GalleryItem.is-loaded .home-GalleryItem-heading span, .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    -webkit-box-shadow: 0 0 0 2px #fff inset;
    box-shadow: 0 0 0 2px #fff inset
}

.lg-outer .lg-pager-thumb-cont {
    background-color: #fff;
    color: #fff;
    bottom: 100%;
    height: 83px;
    left: 0;
    margin-bottom: 20px;
    margin-left: -60px;
    opacity: 0;
    padding: 5px;
    position: absolute;
    width: 120px;
    border-radius: 3px;
    -webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
    transition: opacity .15s ease 0s, transform .15s ease 0s;
    transition: opacity .15s ease 0s, transform .15s ease 0s, -webkit-transform .15s ease 0s;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
}

.lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%
}

.lg-outer .lg-pager {
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, .7) inset;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, .7) inset;
    display: block;
    height: 12px;
    -webkit-transition: box-shadow .3s ease 0s;
    -webkit-transition: -webkit-box-shadow .3s ease 0s;
    transition: box-shadow .3s ease 0s;
    transition: box-shadow .3s ease 0s, -webkit-box-shadow .3s ease 0s;
    width: 12px
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
    -webkit-box-shadow: 0 0 0 8px #fff inset;
    box-shadow: 0 0 0 8px #fff inset
}

.lg-outer .lg-caret {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px dashed;
    bottom: -10px;
    display: inline-block;
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    vertical-align: middle;
    width: 0
}

.lg-fullscreen:after {
    content: ""
}

.lg-fullscreen-on .lg-fullscreen:after {
    content: ""
}

.group {
    *zoom: 1
}

.group:after, .group:before {
    display: table;
    content: "";
    line-height: 0
}

.group:after {
    clear: both
}

.lg-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s
}

.lg-outer * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.lg-outer.lg-visible {
    opacity: 1
}

.lg-outer.lg-css3 .lg-item.lg-current,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-prev-slide {
    transition-duration: inherit !important;
    transition-timing-function: inherit !important
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
    transition-duration: 0s !important;
    opacity: 1
}

.lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    -webkit-box-shadow: 0 10px 24px rgba(1, 2, 2, .1);
    box-shadow: 0 10px 24px rgba(1, 2, 2, .1)
}

.lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap
}

.lg-outer .lg-item {
    display: none !important
}

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
    display: inline-block !important
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%
}

.lg-outer .lg-img-wrap {
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto !important;
    padding: 24px;
    @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.lg-outer .lg-video-cont.lg-has-iframe {
    @media (max-width: 991px) {
        width:100% !important;
        max-width: 100% !important;
        margin-right: 0 !important;
    }
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px
}

.lg-outer .lg-item.lg-complete {
    background-image: none
}

.lg-outer .lg-item.lg-current {
    z-index: 1060
}

.lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important
}

.lg-backdrop, .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity .15s ease 0s;
    transition: opacity .15s ease 0s
}

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
    display: none
}

.lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: #2d2d2e;
    opacity: .7 !important
}

.lg-backdrop.in, .lg-css3.lg-fade .lg-item.lg-current, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
    -webkit-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important
}

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
    -webkit-transition: opacity .1s ease 0s;
    transition: opacity .1s ease 0s
}

.lg-css3.lg-fade .lg-item, .lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transition: opacity .1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-css3.lg-slide.lg-use-left .lg-item {
    opacity: 0;
    position: absolute;
    left: 0
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    -webkit-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s
}

.lg-thumb-spinner {
    position: absolute;
    top: 8px;
    left: 30px;
    color: #dcdcdf;
    font-size: 25px
}

.lg-thumb-spinner:before {
    display: inline-block;
    font: 14px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ''
}


@media (min-width: 64em) {
    .lg-actions {
        display: none;
    }

    .lg-sub-html {
        width: 100%;
        right: 250px;
        bottom: 0;
    }

    .lg-outer .lg-thumb-outer {
        display: block;
    }

    .lg-outer {
        padding: 60px;
    }

    .lg-outer .lg-img-wrap {
        right: 150px;

        .single-image-product-detail & {
            right: 0;
        }
    }
}

@media (min-width: 64em) {
    .lg-outer {
        padding: 60px;
    }
}

.lg-outer .lg-content {
    height: 100%;
    width: 100%;
    top: 0 !important;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    box-shadow: 0 10px 24px rgba(1, 2, 2, .1);
}

.lg-components {
    display: none !important;
}

.lg-outer .lg-thumb {
    display: flex;
    flex-direction: column;
}


.lg-outer .lg-thumb-item {
    @media (min-width: 768px) {
        border: none;
    }
}


// zobrazit počet obrázků v galerii
.p-thumbnails-wrapper {
    width: auto !important;

    &.has-images{
        min-height: 100px !important;
    }
    &.p-thumbnails-wrapper-3d:not(.has-images){
        .iframelink{top:0;}
        margin-bottom: 15px;
    }
}

.p-thumbnails {
    display: block;
    position: absolute;
    top: 0;
    z-index: 3;
    left: -1000px;
    width: 338px;
    height: auto;
    overflow: initial !important;
    box-shadow: 0 10px 35px rgba(1, 2, 2, .1);
    padding: 15px 20px;
    background-color: #fff;

    @media (min-width: 992px) {
        &:hover {
            left: 100%;
        }
    }

    &:before {
        content: '';
        background: #fff;
        position: absolute;
        left: 0;
        top: 36px;
        width: 26px;
        height: 26px;
        transform: rotate(45deg);
    }

    & > div {
        position: relative;
        left: 0 !important;
        width: auto;
        height: auto;
    }

    a {
        text-decoration: none;
        padding: 0 8px;
        width: 75px;
        height: 67px;
        margin-top: 4px;
        margin-bottom: 4px;

        &:hover,
        &.highlighted {
            img {
                border-color: transparent !important;
            }
        }
    }

}

.detail-thumbnails-count {
    position: relative;
    width: auto;
    white-space: nowrap;
    display: inline-flex;
    height: 100px;
    margin-bottom: 11px;
    justify-content: center;
    align-items: center;
    padding-left: 55px;
    cursor: pointer;
    background-image: url('../../images/svg/camera.svg');
    background-size: 34px auto;
    background-repeat: no-repeat;
    background-position: left 10px center;

    @media (min-width: 992px) {
        &:hover,
        .hovered & {
            background-image: url('../../images/svg/camera-orange.svg');

            .p-thumbnails {
                left: 95%;
            }
        }
    }
}

.lg-has-iframe iframe {
    width: 100% !important;
    height: 100% !important;
}