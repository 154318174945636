$color-orange: #f7751e;
$color-black: #1a1937;
$color-grey: #909095;
$color-green: #69c53e;
$color-light-grey: #f7f7f7;
$color-grey-text: #949498;
$color-green-hover: #4D9A2B;

@mixin animation($speed: .2s) {
    transition: all $speed linear;
}

@mixin zoom($zoom: .02) {
    transform: scale($zoom);
}

@mixin desktopxl {
    @media (max-width: 1600px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin phone {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin phonexs {
    @media (max-width: 350px) {
        @content;
    }
}

@-webkit-keyframes scroller-pulse {
    0% {
        -webkit-transform: translate3d(-50%, -12px, 0);
        transform: translate3d(-50%, -12px, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, 12px, 0);
        transform: translate3d(-50%, 12px, 0);
        opacity: 0;
    }
}

@keyframes scroller-pulse {
    0% {
        -webkit-transform: translate3d(-50%, -12px, 0);
        transform: translate3d(-50%, -12px, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, 12px, 0);
        transform: translate3d(-50%, 12px, 0);
        opacity: 0;
    }
}

//Styly napříč celým webem

body {
    background-color: #fff;
    font-size: 15px;

    &:before {
        .search-focused & {
            z-index: 10;
        }

        background-color: rgba(0, 0, 0, 0.8) !important;
    }
}

/*
  Pozadí stránek
*/
#content-wrapper {
    background-color: #fff;
}

//fix mobile view
@media (max-width: 768px) {
    .overall-wrapper {
        padding-top: 0;
    }
}


/*
  MIXINY
*/
.full-bg-white {
    position: relative;
    background: #fff;
    padding: 50px 0;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        top: 0;
        left: -9998px;
        right: 0;
        border-left: 9999px solid #fff;
        box-shadow: 9999px 0 0 #fff;
        display: flex;
        z-index: -1;
    }
}

//plovoucí okna
@media (max-width: 768px) {
    .user-action-in .popup-widget.cart-widget {
        top: 61px;
        height: calc(100% - 61px);
    }
}

/*
Tlačítka
*/
.btn.btn-primary {
    background: var(--able-button-bg);
    border-color: var(--able-button-bg);
    color: var(--able-button-color);
    font-weight: 500;
    padding: 15px;

    &:hover {
        background: var(--able-button-bg-hover);
        border-color: var(--able-button-bg-hover);
        color: var(--able-button-color-hover);
    }
}


/*
  USP
*/
.benefitBanner {
    background: #fff;
    padding: 20px;
    @extend .full-bg-white;

}

/*
    Admin lišta
*/
.admin-bar {
    z-index: 10000;
}

// login style
.user-action {
    z-index: 1006;
    position: relative;

    .btn.btn-conversion, .btn.btn-secondary {
        background: #69c63e;
        border-color: #69c63e;
        color: #fff;
        font-weight: 700;
    }

    a {
        color: #000;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

//registrace
#register-form {
    background: #fff;
    padding: 30px;
}

//inputy
.form-control, select {
    border-radius: 5px;
}

/*
Hodnocení
*/
.stars {
    height: 25px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 300 !important;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.content .sidebar {
    display: flex;
    z-index: 1000;
    //background-color: #fff;
}

/*

.in-znacka .sidebar-inner {
    padding-left: 20px;
}

.in-znacka #content {
    opacity: 0;
}

.in-znacka .sidebar {
    opacity: 0;
}
*/

.sidebar-inner {
    padding-top: 80px;
    min-width:100%;
}

.btn.btn-primary {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    outline: 0;
    white-space: nowrap;
    background-color: $color-orange;
    color: #fff;
    border: 1px solid $color-orange;
    font-weight: 400;
    font-size: 14px;

    &:hover {
        color: $color-orange;
        background-color: transparent;
    }
}

hr {
    border-color: #ccc;
}

.vyber-more {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    outline: 0;
    white-space: nowrap;
    background-color: $color-orange;
    color: #fff;
    border: 1px solid $color-orange;
    font-weight: 400;
    font-size: 14px;

    &:hover {
        color: $color-orange;
        background-color: transparent;
    }

    &--blog {
        margin-top: 50px;
    }

    &--empty {
        color: $color-orange;
        background-color: transparent;
        border: 1px solid $color-orange;

        &:hover {
            background-color: $color-orange;
            color: #fff;
            border: 1px solid $color-orange;
        }
    }
}

.btn.btn-primary {
    color: #fff;

    &:hover {
        background-color: transparent;
        color: $color-orange;
    }
}

.btn.btn-default, a.btn.btn-default {
    color: $color-orange;
    background-color: transparent;
    border-color: $color-orange;
    font-weight: 500;

    &:hover {
        background-color: $color-orange;
        border-color: $color-orange;
        color: #fff;
    }
}

@media (min-width: 992px) {
    .container-narrow {
        width: 1418px;
        margin: 0 auto;
    }
}

.ui-slider .ui-slider-range {
    background-color: #fff;
    border-color: #fff;
}

.ui-slider, .slider-content {
    background-color: $color-orange;
}

@media (min-width: 992px) {
    .sidebar {
        width: 300px;
    }
}

.btn.btn-secondary, a.btn.btn-secondary {
    font-weight: 500;

    &:hover {
        color: #fff;
        background-color: $color-orange;
    }
}

#products-found{
    margin-bottom: 30px;
    min-height:400px;
}
.flags-extra .flag{
    float: left;
    clear: left;
    width: auto;
    max-width: 12em;
    margin-bottom: 0.5ex;
    padding: 4px 8px;
    border-radius: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    opacity: 1;
    height: auto;
    margin-left: 0;
}

/*

@media (min-width: 1440px) {
    .container {
        width: 1178px;
    }
}
@media (min-width: 1441px) {
    .container {
        width: 1418px;
    }
}*/
