.type-detail {

  //Hover DKLabs
  .dkLabFavProductSpan:hover, .dkLabFavDetailSpan:hover{
    color: var(--color-primary) !important;
  }

  #footer{
    margin-top:50px;
  }
  .p-detail-inner-header-mobile {
    .ratings-and-brand {
      display: none;
    }
  }

  @media (max-width: 992px) {
    .p-data-wrapper {
      h1 {
        display: none;
      }
    }
  }

  .breadcrumbs {
    padding-bottom: 0;
  }

  .btn-conversion {
    background: #69c63e;
    border-color: #69c63e;
    color: #fff;
    font-weight: 700;

  }

  .shp-tabs-holder {
    ul.shp-tabs {
      font-weight: 500;
    }
  }

  .p-detail-inner {
    .p-detail-inner-header {
      margin-bottom: 15px;
    }

    h1 {
      margin-bottom: 20px;
    }

    .flags-default {
      display: inline-flex;
      gap: 10px;
      position: relative;
      margin-bottom: 15px;
      flex-wrap: wrap;

      .flag {
        padding: 7px;
      }
    }

    .detail-parameters {
      width: 100%;
      margin-left: 0;

      th, td {
        padding: 10px;
      }

      tr:last-child th, tr:last-child td {
        padding: 10px;
      }

      tr:hover {
        th, td {
          background: #fff;
        }
      }
    }

    .p-to-cart-block {
      padding: 20px;
      background: #e9e9e9;
      .cannotBuy{
        color:#cb0000;
        font-weight: 500;
        margin-bottom: 0 !important;

      }
    }

    .link-icons {
      width: 100%;
      display: flex;

      .link-icon {
        margin-right: 0;
        flex: 1 1 auto;
      }
    }

    .p-gifts-wrapper {
      background: #fff;
      padding: 0 20px;
      margin-top: 15px;
    }

    .social-buttons {
      background: #f4f4f4;
    }
  }

  .shp-tabs-wrapper .tab-content .tab-pane {
    width: 100% !important;
  }

  /*.products-related-header{
    text-align: left;
    font-size: 24px !important;
    font-weight: 300;
  }*/

  #description {

    .basic-description {
      width: 100%;
      background: #fff;
      padding: 30px;

      h2 {
        font-size: 25px;
        font-weight: 300;
      }

      .rate-wrap.row {
        margin: 0 0 40px 0;
      }
    }
  }

  .extended-description {
    background: #fff;
    padding: 30px;
    margin-top: 0;
    width: 100%;

    table.detail-parameters {
      margin-top: 0;
    }

    p:nth-child(1) strong {
      font-size: 25px;
      font-weight: 300;
    }

  }

  .detail-parameters {
    //    width: 90%;
    //margin-left: 5%;

    th, td {
      width: 50%;
      padding: 15px;
      border-bottom: 1px solid #ccc;

      span.row-header-label {
        background: transparent;
      }

      &:after {
        display: none;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .availability-label {
        margin-left: 0;
      }

    }

    tr:hover {
      th, td {
        background: #f4f4f4;
      }
    }

    tr:last-child th, tr:last-child td {
      padding: 15px;
      border-bottom: 0;
    }
  }

  #ratingTab, #productDiscussion {
    background: #fff;
    padding: 30px;
  }

  .products-header {
    margin: 50px 0 10px 30px;
    font-size: 24px;
    text-align: left;
    font-weight: 300;

  }

  #cboxOverlay {
    z-index: 1004;
  }

  #colorbox {
    z-index: 1005;
  }

}

.products-related-wrapper{
  padding-bottom: 0;
  .products-block{
    padding-bottom: 0;
    max-height: 660px;
  }
}

.type-detail .detail-parameters th, .type-detail .detail-parameters td {
  width: auto;
}

.type-detail {
  #ratingTab, #productDiscussion {
    display: none !important;
  }

  #description {
    .description-inner {
      position: relative;

      .detail-3d {
        position: relative;
        flex: 0 0 50%;
        max-height: 500px;
        max-width: 50%;
        //margin-left: 30px;
        @media (max-width: 992px) {
          max-width: 100%;
          flex: none;
        }

        .detail-3D-wrapper {
          max-width: 100%;
          max-height: 500px;
          height: 500px;
          position: relative;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .slick-list {
          max-height: 500px;
          height: 500px;

          .slick-track {
            height: 500px;
          }
        }

        .slick-prev, .slick-next {

          z-index: 10;
        }

        .slick-prev:before, .slick-next:before {
          color: $color-grey;
          font-size: 30px;
        }
      }

      #detail-3d-photo {
        position: relative;
        flex: 0 0 50%;
        max-height: 500px;
        max-width: 600px;
        padding-left: 30px;
        @media (max-width: 992px) {
          padding-left: 0;
        }

      }
    }

    .basic-description {
      flex: 0 0 50%;
      color: #909095;
      font-weight: 400;
      font-size: 15px;
      line-height: 2;
      @include tablet-portrait {
        flex: 1 1 auto;
      }
      @media (max-width: 992px) {
        padding-right: 0;
      }

      p {
        color: #909095;
        font-weight: 400;
        font-size: 15px;
        line-height: 2;
      }
    }
  }

  #tab-content {
    padding: 0 30px 0 30px;

    .basic-description {
      padding-left: 0;
    }
  }

  #relatedFiles {
    padding-left: 0;
    list-style-type: none;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;

      a {
        font-size: 18px;
        font-weight: 500;
      }

      &:before {
        display: none;
      }

      img {
        height: 20px;
        width: auto;
        margin-right: 5px;
      }
    }
  }


  #productBlog {
    margin-top: 0;
    margin-bottom: 30px;


    h2 {
      margin-bottom: 10px;

    }
  }


  .shp-accordion-wrapper {
    margin-top: 0;

    #accordion-content .shp-accordion {
      .shp-accordion-link {
        font-weight: 300;
        color: #1a1937;
      }

      .shp-accordion-content {
        #productBlog {
          h2 {
            display: none;
          }
        }
      }
    }
  }


  #productVideos {
    max-width: 50% !important;
    margin-right: auto;
    margin-left: unset;
    margin-top: 0;
    margin-bottom: 30px;
    @media (max-width: 992px) {
      max-width: 100% !important;
    }

  }

  .responsive-nav {
    margin-bottom: 0;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .p-detail-inner {
    margin-top: 40px;
    @media (max-width: 768px) {
      max-width: 100% !important;
    }

    .detail-parameters {
      th, td {
        border-bottom: none;
        padding: 5px 0;
        padding-left: 0 !important;
      }

      th {
        color: $color-grey;
      }
    }

    .p-to-cart-block {
      background-color: #fff;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid $color-light-grey;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 0;

      .p-final-price-wrapper {
        flex-basis: 100%;
        display: flex;
        flex-shrink: 0;
        margin-bottom: 20px;
      }

      .btn-conversion {
        width: auto;
        max-width: 200px;

        &:hover {
          background-color: $color-green-hover;
          border-color: $color-green-hover;
        }
      }

      .price-final-holder {
        color: $color-green;
      }

      .add-to-cart-button {
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.p-to-cart-block .add-to-cart {
  margin-left: unset;
}

.quantity {
  padding-left: 30px;
  padding-right: 30px;

  .decrease:hover:before, .increase:hover:before {
    background-color: $color-light-grey;
  }
}

.type-detail .p-detail-inner .detail-parameters {
  width: 70%;
  @include tablet-landscape {
    width: 100%;
  }

  th {
    width: 205px;
  }
}

#productDiscussion {
  display: none;
}

.shp-tabs-holder {
  background-color: #f7f7f7;
  box-shadow: 0 0 0 100vw #f7f7f7;
  clip-path: inset(0 -100vw);

  .p-detail-tabs {
    .shp-tab {
      a {
        color: #24282b;
        font-family: "Montserrat", Arial, sans-serif;
        font-weight: 400;
        font-size: 16px !important;
      }

      &.active {
        text-decoration: none !important;

        a {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: none !important;

        a {
          color: $color-orange;
        }
      }
    }

    .shp-tab[data-testid="tabDiscussion"] {
      display: none;
    }
  }
}

.tab-content > .tab-pane {
  display: block !important;

  &.fade {
    opacity: 1 !important;
  }

  h2:first-child {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

#relatedFiles {
  margin-top: 0;
  margin-bottom: 30px;

  h2 {
    margin-bottom: 30px;
  }
}

.type-detail .p-detail-inner .flags-default .flag {
  padding: 8px 12px;
  font-size: 12px;
  max-width: none;
}

.type-detail .p-detail-inner .p-detail-inner-header {
  display: flex;
  flex-direction: column;

  .ratings-and-brand {
    order: 3;
    margin: 0;
  }
}

.type-detail .p-detail-inner .p-gifts-wrapper {
  padding-left: 0;
  margin-bottom: 20px;
}

.iframelink {
  position: relative;
  top: 28px;
  float: left;

  img {
    height: 30px;
    width: auto;
    margin: 12px 15px 0 0;
  }

  &:hover {
    img {
      filter: brightness(0) saturate(100%) invert(47%) sepia(98%) saturate(1182%) hue-rotate(348deg) brightness(99%) contrast(96%);
    }
  }
}

.question-detail {
  box-shadow: 0 0 0 100vw #f7f7f7;
  clip-path: inset(0 -100vw);
  background-color: #f7f7f7;
  position: relative;
  padding-top: 30px;
  margin: 0;

  &__item {
    min-height: 350px;
    padding: 100px 50px 50px;
    height: 100%;
    @include tablet-landscape {
      padding: 50px 30px;
    }
    @include tablet-portrait {
      padding: 50px 10px !important;
    }
    @media (min-width: 600px) {
      &--left {
        background: url(https://550310.myshoptet.com/user/documents/images/question.jpg) right top no-repeat;
      }
    }
    @media (max-width: 1439px) {
      &--left {
        background-size: 150px;
        background-position: right bottom;
      }
    }

    &--right {

    }

    @media (max-width: 1439px) {
      padding: 30px 50px 50px;
      min-height: 270px;
    }
  }

  .question-button {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    outline: 0;
    white-space: nowrap;
    background-color: $color-orange;
    color: #fff;
    border: 1px solid $color-orange;
    font-weight: 500;
    font-size: 14px;

    &--empty {
      color: $color-orange;
      background-color: transparent;
      border: 1px solid $color-orange;

      &:hover {
        background-color: $color-orange;
        color: #fff;
        border: 1px solid $color-orange;
      }
    }
  }
}

.type-detail .p-detail-inner .link-icons {
  a {
    color: $color-grey;
  }
}

.p-detail-inner {
  padding-bottom: 50px;

  body.mobile & {
    padding-bottom: 0;
  }

}

.p-thumbnails a {
  margin-right: 6px;
  margin-left: 0;
}

.type-detail, .type-manufacturer-detail, .type-category {
  h4, .category-title {
    font-weight: 500;
  }

  .products-top {
    background: #fff;
    padding: 30px 0 !important;
  }

  .products-top .product .p .name span {
    color: #000;
  }

  .products-top .product .p .name:hover span {
    text-decoration: underline;
  }

  .products-top .product .p .price strong {
    font-size: 15px;
  }

  .products-top .product .p .availability {
    margin: 5px 0 !important;
  }

  .products-top .product .p .availability span {
    font-size: 13px;
  }

  #category-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 13px;
    color: #666;
  }

  #category-header #products-listing-order {
    font-size: 13px;
    color: #666;
  }

  .category-header .dropdown button {
    font-size: 14px;
  }

  #products {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    z-index: 10;
    position: relative;
  }

  #products .product {
    width: 100%;
    padding: 0 !important;
    //min-height: 465px;

    @include tablet-portrait {
      min-height: auto !important;
    }
  }

  @include phone {
    #filters-wrapper {
      width: 100%;
    }
    #filters-wrapper {
      top: 0 !important;
      height: 100% !important;
    }
    #filters {
      width: 100%;
    }
    #filters .filter-section form fieldset > div {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    #products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1200px) {
    #products {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 767px) {
    #products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 480px) {
    #products {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) and (hover: hover) {
    #products .product .p .p-code, #products .product .p .p-in .p-bottom > div .p-tools {
      display: none;
    }
    #products .product.isLarge {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
  @media (min-width: 768px) and (max-width: 767px) {
    #products .product.isLarge {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  @media (min-width: 768px) {
    #products .product.isLarge .p {
      align-items: center;
      justify-content: center;
    }
    #products .product.isLarge .p .p-code {
      position: absolute;
      top: 10px;
    }
    #products .product.isLarge .p .p-in .p-bottom > div .p-tools {
      display: inline-block;
    }
  }
  @media (min-width: 768px) {
    #products .product:hover .p .p-code, #products .product:hover .p .p-in .p-bottom > div .p-tools {
      display: inline-block;
    }
    #products .product:hover:not(.isLarge) .p {
      top: -20px;
      height: auto;
      min-height: 110%;
      width: 105%;
      margin: 0 auto;
      transform: translateX(-50%);
      left: 50%;
    }
  }

}

.p-short-description {
  margin-bottom: 0 !important;

  p {
    color: #909095;
    font-weight: 400;
    font-size: 15px;
    line-height: 2;
  }
}

.type-detail .extended-description {
  padding: 30px 0 0;

  .detail-parameters {
    tr {
      border-bottom: none;

      &:nth-child(even) {
        background-color: #f7f7f7;
      }
    }

    th, td {
      border-bottom: none;
    }

    .question-tooltip {
      display: none;
    }
  }
}

#extendedDescription .detail-parameters tr:first-child {
  display: none;
}


.mobile {
  .slick-prev {
    left: -5px;
  }

  .slick-next {

    right: 5px;
  }
}

.p-image .flags{
  display:none !important;
}

.shp-accordion-content #relatedFiles h2{
  display:none;
}