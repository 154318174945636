:root {
  --template-font: "Montserrat";
  --header-height: 40px;
  --able-bg-page: #f4f4f4;
  --able-main-color: var(--color-primary);
  --able-main-color-hover: var(--color-primary-hover);
  --able-button-bg: var(--color-primary);
  --able-button-bg-hover: var(--color-primary);
  --able-button-color: #000;
  --able-button-color-hover: #000;
}
@import 'styles/base.scss';
@import 'styles/all.scss';
@import 'styles/client.scss';
@import 'styles/sticky.scss';
@import 'styles/headers.scss';
@import 'styles/banners.scss';
@import 'styles/benefit.scss';
@import 'styles/blog.scss';
@import 'styles/breadcrumbs.scss';
@import 'styles/sliders.scss';
@import 'styles/product_small.scss';
@import 'styles/footer.scss';
@import 'styles/pages.scss';
@import 'styles/cart.scss';
@import 'styles/manufacturers.scss';
@import 'styles/cookie.scss';
@import 'styles/modal.scss';
@import 'styles/pagination.scss';
@import 'styles/znacka.scss';
@import 'styles/msg.scss';
@import 'styles/gallery.scss';
@import 'styles/lang-switcher.scss';

//vendors
@import '../../../node_modules/lightgallery/css/lightgallery.css';
@import '../../../node_modules/lightgallery/css/lg-thumbnail.css';

@import '../../../node_modules/slick-carousel/slick/slick.css';
@import '../../../node_modules/slick-carousel/slick/slick-theme.css';

//Typy stránek
@import 'styles/homepage.scss';
@import 'styles/detail.scss';
@import 'styles/chart.scss';
@import 'styles/category.scss';
