.blog-show-more {
  color: $color-orange;
  margin-top: 10px !important;
  font-weight: 500;
}

.blog {
  table {
    tr {
      td {
        padding: 15px;
        @include tablet-portrait {
          padding: 5px;
        }
      }
    }
  }

  iframe {
    width: 80%;
    margin: 0 10%;
    aspect-ratio: 2/1;
  }

}

.blog-category {
  h1 {

  }

  .paging {

    float: right;

    li {
      list-style: none;
      float: left;


      &:before {
        content: "";
        margin: 0;
      }

      a {
        width: 20px;
        display: inline-block;
        padding: 5px;

        &.active {
          background-color: #F7F7F7;
        }
      }
    }
  }

  &__list {
    border: 1px solid #efeff2;
    border-left: 0;
    border-right: 0;
    text-align: center;
    display: block;
    font-size: 0;
    line-height: 0;
    margin-bottom: 55px;
    overflow: hidden;
    overflow-x: auto;
    white-space: normal;
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline-block;
      margin-bottom: -1px;
      list-style-type: none;
      display: inline;

      &:before {
        content: '';
      }

      a {
        font-weight: 500;
        font-size: 15px;
        color: #909095;
        position: relative;
        display: inline-block;
        padding: 20px;
        line-height: 24px;
        text-decoration: none;
        -webkit-transition: color .18s ease;
        transition: color .18s ease;
      }

      &:hover {
        a {
          color: #24282b;
        }
      }

      &.active {
        a {
          color: #24282b;
          border-bottom: 2px solid $color-orange;
        }
      }
    }
  }
}

.in-nase-novinky {
  article {
    header {
      display: none;
    }
  }
}

.blog-cells {
  text-align: center;

  &__item {
    width: 33%;
    display: inline-block;
    padding: 20px;
    margin-bottom: 50px;
    text-align: left;

    span {
      display: block;
    }


    &:first-child, &.largee {
      width: calc(100% - 40px);
      height: 500px;
      position: relative;
      padding: 0 0 50px 0;
      border-bottom: 1px solid #efeff2;
      margin-bottom: 10px;

      .type-product & {
        width: 100%;
        margin-bottom: 30px;
        .image{
          width:70%;
          iframe {
            width: 100%;
            height: 460px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }

      .text {
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        background-color: #75736e !important;
        height: 460px;
        padding: 0 50px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include tablet-portrait {
          padding: 0 0 !important;
        }

        .title {
          color: #fff;
        }

        .description {
          color: #fff;
        }

        .read-article {
          padding: 0 20px;
          border: 1px solid $color-orange;
          background-color: $color-orange;
          color: #fff;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #f76d11;
            border-color: #f76d11;
          }
        }
      }

      .image {
        height: 460px;

        iframe {
          width: 70%;
          height: 460px;
        }

        img {
          object-fit: cover;
          width: 70%;
          height: 100%;
        }
      }

      @media (max-width: 992px) {
        height: auto;
        .image {
          width: 100% !important;
          margin-bottom: 20px;
          padding: 0 50px;

          img, iframe {
            width: 100%;
          }
        }

        .text {
          position: relative;
          height: 360px;
          width: 100%;
        }
      }
      @include tablet-portrait {
        .image {
          padding: 0;
        }

      }

    }

    &.largee {
      margin-top: 20px;

      .text {
        background-color: #fff !important;
        justify-content: flex-start;

        .title {
          color: $color-black;
          height: auto;
        }

        .description {
          color: $color-black;
        }
      }
    }

    @include tablet-landscape {
      width: 50%;
    }
    @include phone {
      width: 100%;
    }

    &:hover {
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
    }

    .image {
      margin-bottom: 30px;
      display: block;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      iframe {
        width: 100%;
        aspect-ratio: 2/1;
      }
    }

    .text {
      padding: 0 !important;
      margin-bottom: 0;

      .title {
        font-weight: 300;
        height: 82px;
        overflow: hidden;
        font-size: 24px;
        color: #24282b;
        margin-bottom: 10px;
        display: block;

        .type-product & {
          font-size: 20px;
        }
      }

      .description {
        font-weight: 400;
        font-size: 15px;
        color: #909095;
        line-height: 2.1;
        margin-bottom: 20px;
        height: 126px;
        overflow: hidden;
        //white-space: nowrap;
        text-overflow: ellipsis;
      }

      .read-article {
        font-weight: 500;
        font-size: 13px;
        color: #f7751e;
        text-decoration: none;

        &:hover {
          color: #24282b;
        }
      }
    }
  }
}

.type-posts-listing {
  .news-wrapper, aside.sidebar {
    display: none;
  }
}

.posts-listing-list {
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #efeff2;
  padding-left: 0;

  li {
    list-style-type: none;
    margin-bottom: 15px;

    &:before {
      font-family: shoptet;
      content: '\e910';
      color: #f7751e;
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

#blog {

  ul:not(.blog-category__list):not(.paging) li {
    padding-left: 20px;
    position: relative;

    &:before {
      display: inline-block;
      //font: 14px/1 'icons';
      font-family: shoptet;
      content: '\e910';
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      //content: '';
      font-size: 8px;
      color: #f7751e;
      position: absolute;
      left: 10px;
      top: 14px;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.image, .video__wrapper {
  &.youtube {
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' version='1.1' viewBox='0 0 68 48' width='100%25'%3E%3Cpath class='ytp-large-play-button-bg' d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='%23f00'%3E%3C/path%3E%3Cpath d='M 45,24 27,14 27,34' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 68px;
      position: absolute;
      width: 100%;
      height: 100%;
      display: inline-block;

    }
  }
}
