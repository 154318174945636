.type-category {


  h4, .category-title {
    font-weight: 500;
  }

  .products-top {
    background: #fff;
    @extend .full-bg-white;
    padding: 30px 0 !important;

    .product {
      .p {
        .name {
          span {
            color: #000;
          }

          &:hover {
            span {
              text-decoration: underline
            }
          }
        }

        .price strong {
          font-size: 15px;
        }

        .availability {
          margin: 5px 0 !important;

          span {
            font-size: 13px;
          }
        }
      }


    }

  }


  #category-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 13px;
    color: #666;

    #products-listing-order {
      font-size: 13px;
      color: #666;

    }
  }


  .subcategories li {
    padding: 10px;

    a {
      background: #fff;

      .text {
        font-weight: 500;
        font-size: 16px;
      }

      .image img {
        max-height: 40px;
      }
    }
  }

  .category-header .dropdown button {
    font-size: 14px;
  }


  /*
  Výpis produktů
  */
  #products {
    display: grid;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-column-gap: 10px;
    grid-row-gap: 10px;


    .product {
      width: 100%;
      padding: 0 !important;
      min-height: 470px;


      @media (min-width: 768px) {
        .p .p-code, .p .p-in .p-bottom > div .p-tools {
          display: none;
        }

        &.isLarge {
          grid-column: span 2;
          grid-row: span 2;

          @media (max-width: 767px) {
            grid-column: span 1;
            grid-row: span 1;
          }

          .p {
            align-items: center;
            justify-content: center;

            .p-code {
              position: absolute;
              top: 10px;
            }

            .p-in .p-bottom > div .p-tools {
              display: inline-block;
            }
          }
        }


        &:hover {

          .p .p-code, .p .p-in .p-bottom > div .p-tools {
            display: inline-block;
          }

          &:not(.isLarge) {
            .p {
              top: -20px;
              height: auto;
            }
          }
        }
      }
      @media (hover: none) {
        .p .p-code, .p .p-in .p-bottom > div .p-tools {
          display: inline-block;
        }

      }

    }
  }

}

.category-content-wrapper {
  background-color: #fff;
  position: relative;
  padding: 50px 0;
}

.type-category {
  .category-top {
    position: relative;

    .category-perex {
      margin-bottom: 80px;
      @include tablet-portrait {
        margin-bottom: 30px;
      }
    }
  }

  #content {
    opacity: 0;

    .category-title {
      text-align: center;
      position: absolute;
      color: #fff;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      background-color: rgba(51, 51, 51, .8);
      padding: 20px 30px;
      font-size: 40px;
      z-index: 10;
      font-weight: 300;

      @include desktop {
        top: 0px;
        position: relative;
        margin-bottom: 0;
        left: 0;
        transform: translateX(0);
        width: 100%;
      }

    }

    .category-top-noperex {
      .category-title {
        position: relative;
        top: 0;
        background: none;
        color: #000;

        .pagination-appendix {
          display: none;
        }
      }

      .category__secondDescription {
        margin-top: 0;
        z-index: 5;
      }
    }
  }

  .category__secondDescription {
    position: relative;
    margin: 0 auto;
    margin-top: -120px;
    margin-bottom: -20px;
    background-color: #fff;
    text-align: center;
    width: 80%;
    padding: 40px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
    z-index: 4;

    @include tablet-portrait {
      margin-top: -60px;
      padding: 40px 15px;
      width: 90%;

    }
  }

  .products-top {
    .product {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.subcategories {
  background-color: $color-light-grey;
  padding: 50px 15px;
  box-shadow: 0 0 0 100vw #f7f7f7;
  clip-path: inset(0 -100vw);
  @include tablet-portrait {
    padding: 30px 10px;
  }

  &.with-image {
    li {
      padding: 10px;

      a {
        background: #fff;
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 10px 0;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);

        .image {
          width: 80%;
          padding-right: 0;
          margin-bottom: 10px;
          @include animation();

          img {
            max-height: 180px;
            margin-bottom: 10px;
            padding-top: 10px;
          }
        }

        .text {
          height: 54px;
          max-width: 90%;
          color: $color-orange;
          font-size: 16px;
          text-align: center;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;

          @include animation();
        }

        &:hover {
          .image {
            transform: scale(1.1);
          }

          .text {
            text-decoration: none;
            font-weight: 400;
            color: $color-black;
          }
        }
      }
    }
  }
}

.category-header #products-listing-order:hover {
  color: $color-orange !important;
}

.category-header .dropdown .dropdown-menu label:hover {
  color: $color-orange;
}

@media (min-width: 992px) {
  .type-category #products {
    margin-top: 30px;
  }
}

#productsTop {
  .product {
    width: 100% !important;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    padding-bottom: 10px;

    .image {
      width: auto !important;
      height: 50px;
      min-height: unset !important;
      display: flex;
    }

    .p-in {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .p-in-in {
        display: flex;
        align-items: center;
        flex-basis: 90%;

        .ratings-wrapper {
          margin-left: 20px;
        }
      }

      .p-bottom {
        flex-basis: 10%;
        justify-content: flex-end;
        display: flex;
      }
    }
  }

  .p {
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
  }
}

.welcome-wrapper ul li::before, .category-perex ul li::before, .category__secondDescription ul li::before, .p-short-description ul li::before, .tab-content ul li::before, article ul li::before {
  color: $color-orange;
  font-size: 12px;
  margin-right: 10px;
}

.category-overlay {
  max-height: 70px;
  overflow: hidden;
  position: relative;
  transition: all .2s linear;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    transition: all .3s linear;
  }

  &.active {
    max-height: 4000px;

    &:after {
      content: none;
    }
  }
}

.category-more, .category-less {
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  margin: 5px 0;

  &:hover {
    text-decoration: none;
  }
}

.category-less {
  display: none;

  &.active {
    display: block;
  }
}

.scroller {
  background: rgba(85, 85, 82, .9);
  border-radius: 34px;
  display: inline-block;
  width: 34px;
  height: 54px;
  -webkit-transition: background-color .24s;
  transition: background-color .24s;
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    content: '↓';
    position: absolute;
    color: #fff;
    left: 50%;
    font-size: 20px;
    -webkit-animation: scroller-pulse 1.4s infinite;
    animation: scroller-pulse 1.4s infinite;
    top: 5px;
  }
}

.category-content-wrapper-znacka {
  display: flex;

  .sidebar {
    .sidebar-inner {
      padding-top: 30px;
      top: 90px;
    }
  }
}

.subcategories {
  > li {
    width: 20%;
    @include desktop {
      width: 25%;
    }
    @include tablet-landscape {
      width: 33.3333%;
    }
    @include tablet-portrait {
      width: 50%;
    }
  }
}

.category-perex {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}

@media (max-width: 767px) {
  #filters-wrapper {
    background-color: #f7f7f7;

    .filter-sections {
      background-color: #f7f7f7;
    }
  }
}

#filters {
  .h4title {
    margin-top: 0;
    text-align: left;
    font-size: 18px;
  }
}

/*
#filters{
    .filter-sections h4:after {
        background: transparent none no-repeat 50%;
        background-image: none;
        background-size: auto;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS4zIiBkPSJNMCAxLjM1aDExIi8+PC9zdmc+");
        background-size: 12px auto;
        content: "";
        font-size: 20px;
        font-weight: 400;
        height: 20px;
        position: absolute;
        right: 0;
        text-align: right;
        top: 1px;
        width: 20px;
    }
}*/

.filter-section-long {

  .filter-section-overlay {
    max-height: 300px;
    overflow: hidden;
    position: relative;
    transition: all .2s linear;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7);
      transition: all .3s linear;
      height: 50px;
      width: 100%
    }


  }

  .filter-section-more, .filter-section-less {
    display: block;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    margin: 5px 0;

    &:hover {
      text-decoration: none;
    }
  }

  .filter-section-less {
    display: none;
  }

  &.active {
    .filter-section-overlay {
      max-height: 4000px !important;

      &:after {
        content: none;
      }
    }

    .filter-section-less {
      display: block;
    }

    .filter-section-more {
      display: none;
    }
  }
}

.filter-sections {
  .h4title {

    position: relative;
    cursor: pointer;
    margin-bottom: 10px;

    span.count{
      display: none;
    }

    &:after {
      background: transparent none no-repeat 50%;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS4zIiBkPSJNMCAxLjM1aDExIi8+PC9zdmc+");
      background-size: 12px auto;
      content: "";
      font-size: 20px;
      font-weight: 400;
      height: 20px;
      position: absolute;
      right: 0;
      text-align: right;
      top: 1px;
      width: 20px;
    }
  }

  .filter-section.collapsed {
    .h4title {
      span.count {
        display: inline-block;
        padding-left: 5px;
      }

      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjEuMyIgZD0iTTAgNS4zNWgxMU01LjM1IDExVjAiLz48L3N2Zz4=") !important;
      }
    }

    form {
      display: none;
    }

    .filter-section-overlay:after, .filter-section-more, .filter-section-less {
      display: none;
    }
  }
}

#selected-filters{
  text-align: left;
  border-bottom: 1px solid #ccc;
  label,.slider-header{
    position: relative;
    display: inline-block;
    width:auto;
    font-size: 11px;
    padding:4px 14px 4px 5px;
    border:1px solid #ccc;
    margin: 0 5px 5px 0;
    float: left;
    cursor: pointer;
    &:after{
      content: "×";
      position: absolute;
      right: 2px;
      top: 1px;
      font-size: 15px;
    }
    &:hover{
      background: #e7e7e7 ;
    }
  }
  label{
   span{display: none;}
  }
  .slider-header{
    span{
      &.from,&.to{width:auto;position: relative}

      &.from{
        padding-right: 10px;
        &:after{
          content: "-";
          position: absolute;
          right: 2px;
          top: -5px;
          font-size: 15px;
        }
      }
    }

  }
  #clear-filters{
    font-size: 12px;
    margin-bottom: 10px;
  }
}