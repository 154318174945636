/*
  Homepage
*/
.type-index {


  .homepage-group-title {
    font-size: 25px;
    font-weight: 300;
    margin: 40px 0 10px 0;
  }

  .homepage-blog-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;

    .news-item {
      flex-basis: 25%;
      width: 25%;
      @media (max-width: 991px) {
        flex-basis: 50%;
        width: 50%;
      }
      @media (max-width: 576px) {
        flex-basis: 100%;
        width: 100%;
      }

      .image{
        @media (max-width: 991px) {
          img,iframe{
            width:100%;
            //aspect-ratio: 4/2;
            height: auto;
          }
        }

        @media (min-width: 991px) {
          img,iframe{
            width:203px;
            height: auto;
          }
        }
        @media (min-width: 1200px) {
          img,iframe{
            width:255px;
            height: auto;
          }
        }

        @media (min-width: 1440px) {
          img,iframe{
            width:315px;
            height: auto;
          }
        }

      }

      .text {
        color: #000;

        .title {
          font-size: 19px;
          line-height: 24px;

          &:hover {
            text-decoration: none;
          }
        }

        .description {
          color: #999;
        }
      }

      &:hover {
        background: #fff;
      }
    }
  }

  .banners-content.body-banners {

    @extend .full-bg-white;
  }

  .footer-banners {

    @extend .full-bg-white;

    .footer-banner {
      padding: 10px;

      a {
        overflow: hidden;
      }

      img {
        border-radius: 3px;
      }

      .extended-banner-texts {
        overflow: hidden;

        .extended-banner-link {
          width: 100%;
          height: 50px;
          bottom: 0;
          top: auto;
          line-height: 50px;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          background: rgba(255, 255, 255, 0.25);
          font-size: 15px;
          color: #000;

          &:after, &:before {
            border-right: 2px solid;
            content: "";
            display: block;
            height: 8px;
            margin-top: -6px;
            position: absolute;
            transform: rotate(135deg);
            right: 30px;
            top: 50%;
            width: 0;
          }

          &:after {
            margin-top: -1px;
            transform: rotate(45deg);
          }
        }

        &:hover {
          .extended-banner-link {
            border-top: 1px solid rgba(255, 255, 255, .35);
            background: rgba(255, 255, 255, 0.35);
          }
        }
      }

      @media (max-width: 1200px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 50%;
        float: left;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

.homepage-products-1, .homepage-products-2 {
  overflow: hidden !important;
}

.products-block .product .p .p-in .p-bottom > div .prices * {
  font-size: 14px;
}

.type-index .homepage-group-title {
  font-size: 34px;
  font-weight: 500;
}

.product-slider .products-block .product:hover .p {
  position: initial;
  top: unset;
  left: unset;
  width: initial;
  transform: scale(1.05);
}

.product-slider-holder .products-block {
  //min-height: 520px;
}

.products-block .product:hover .p {
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
}

@media (max-width: 767px) {
  .welcome-wrapper{
    margin-top: 0;
    padding-top: 0;
  }
}
.welcome {
  text-align: center;

  .video__wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-top: 30px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.product-slider-holder .product-slider-pagination {
  display: none;
}
.product-slider-holder {
  text-align: center;
}

.type-index .homepage-blog-wrapper {
  justify-content: center;
}

html body .product-slider-holder .product-slider-navigation.navigation-prev {
  &:before {
    height: 42px;
    width: 28px;
    content: '';
    border-radius: 0;
    background: url("data:image/svg+xml,%3Csvg width='5.6569px' height='8.48529px' viewBox='0 0 5.6569 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-286.000000, -237.000000)' fill='%23909095' fill-rule='nonzero'%3E%3Cg id='arrow-drop-left-line' transform='translate(286.000000, 237.000000)'%3E%3Cpolygon id='Path' points='2.8284 4.24269 5.6569 7.07109 4.2426 8.48529 0 4.24269 4.2426 0 5.6569 1.41422'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 0 0 /  28px 42px no-repeat !important;;
  }

  &:hover {
    &:before {
      background: url("data:image/svg+xml,%3Csvg width='5.6569px' height='8.48529px' viewBox='0 0 5.6569 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-286.000000, -237.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='arrow-drop-left-line' transform='translate(286.000000, 237.000000)'%3E%3Cpolygon id='Path' points='2.8284 4.24269 5.6569 7.07109 4.2426 8.48529 0 4.24269 4.2426 0 5.6569 1.41422'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 0 0 /  28px 42px no-repeat !important;
    }
  }
}

html body .product-slider-holder .product-slider-navigation.navigation-next {
  &:before {
    height: 42px;
    width: 28px;
    content: '';
    border-radius: 0;
    background: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23909095' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 0 0 /  28px 42px no-repeat !important;
  }

  &:hover {
    &:before {
      background: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 0 0 /  28px 42px no-repeat !important;
    }
  }
}

.product-slider-holder .product-slider-navigation {
  opacity: 1 !important;
  top: 40%;
}

.product-slider-holder .product-slider {
  margin-bottom: 0;
}

.type-index .homepage-blog-wrapper .news-item {
  transition: all .2s;

  &:hover {
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
  }
  @include tablet-portrait {
    &:nth-of-type(1n+4){ display:none; }
  }
}

.whyunimax {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.67;
    margin-bottom: 20px;
  }

  &__wrapper {
    position: relative;
    display: inline-block;
    text-align: left;
    margin-top: 20px;
    .category-overlay {
      max-height: 300px;
    }
  }

  &__item {
    padding: 0 30px;
    background-position: -15px 0;
    background-repeat: no-repeat;
    padding-left: 50px;

    @media (max-width: 767px) {
      padding: 0 0px !important;

      p{
        text-align: justify;
      }
    }

    p {
      font-weight: 400;
      color: #909095;
      line-height: 1.9;

      strong, b {
        color: #000;
        font-weight: bold;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      width: 50px;
      height: 100%;
      background-size: auto;
      background-repeat: no-repeat;
    }

    &--1 {
      &:before {
        content: '';
        //background-image: url(../images/whyunimax/basket.svg);
      }
    }

    &--2 {
      &:before {
        content: '';
        //background-image: url(../images/whyunimax/crossword.svg);
      }
    }

    &--3 {
      &:before {
        left: 12px;
        content: '';
        //background-image: url(../images/whyunimax/box.svg);
      }
    }

    &--4 {
      &:before {
        content: '';
        //background-image: url(../images/whyunimax/board.svg);
      }
    }
  }
}