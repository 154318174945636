//Horní pruh informace pro Vás
.top-navigation-bar {
  z-index: 1004;
  position: relative;
  font-size: 13px;
}

//Hlavička
#header {
  z-index: 1003;
  position: relative;
  font-size: 13px;

  //panel s logem
  .container {
    min-height: var(--header-height);

    @media (max-width: 767px) {
      min-height: 0;
    }

    .navigation-buttons {
      .btn.btn-icon, a.btn.btn-icon {
        font-size: 17px;
      }

      a[data-target="cart"] {
        &::before {
          font-size: 20px;

          @media (min-width: 480px) and (max-width: 767px) {
            position: absolute;
            top: 10px;
            left: 12px;
            width: 60px !important;
            height: 60px !important;
            background-size: 28px !important;
            background-repeat: no-repeat;
            background-position: center;

            .pp-sticky & {
              top: 0;
              left: 0;
            }

          }

        }
      }

      @media (max-width: 767px) {
        .navigation-buttons {
          a {
            height: 60px !important;
            width: 60px !important;

            &:before {
              line-height: 60px !important;
            }
          }

          @media (max-width: 767px) {
            a[data-target="search"]::before {
              font-size: 20px;
            }
          }
        }
      }
    }

    // hledání
    @media (min-width: 768px) {
      .search {
        .search-input-icon {
          &:before {
            font-size: 19px;
          }
        }
      }
      .searchWhisperer {
        margin-top: 3px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
      }
      .searchWhisperer__buttonWrapper {
        border-top: 1px solid #cfcfcf;
      }
    }
  }

  //hlavní menu
  .header-bottom {
    background: var(--able-main-color);

    .menu-level-1, #navigation {
      background: transparent;
    }


    @media (max-width: 767px) {

      #navigation{
        max-height: 99vh;
      }
      .pp-sticky & {
        #navigation {
          top: 60px;
        }
      }
    }

    @media (min-width: 768px) {
      #navigation {
        height: 60px;

        &.fitted .navigation-in .menu-level-1 {
          justify-content: space-between;
        }
      }

    }

    #navigation .navigation-in {
      font-size: 14px;

      .top-navigation-bar-menu {
        display: none;
        width: 100%;
        @media (max-width: 767px) {
          display: block;
          a {
            display: inline-block;
            font-size: 13px;
            padding: 10px 8px;
          }
        }
      }

      .navigationActions {
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      > ul > li {
        & > a {
          line-height: var(--header-height);
          padding-top: 0;
          padding-bottom: 0;
          @media (max-width: 767px) {
            b {
              padding-top: 10px;
              padding-bottom: 10px;

            }
          }
        }

        &:hover {
          > a {
            background-color: var(--able-main-color-hover);

            b {
              text-decoration: none;
            }
          }
        }

        .menu-level-2 {
          align-items: start;

          > li {
            align-items: start;
          }

          .menu-level-3 {
            overflow: visible;
            max-height: none;
            display: inline-block;
            width: 100%;

            & > li {
              display: block;
              width: 100%;
              color: #fff;
              font-size: 0;


              & > a {
                font-size: 13px;
                color: #666;
                display: inline-block;
                width: calc(100% - 3px);
                padding: 5px 10px;

                @media (max-width: 767px) {
                  padding-left: 60px;
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .switcher{
        width: 100%;
        list-style-type: none;
        display: table;
        margin-bottom: 0;
        border-collapse: collapse;
        li{
          display: table-cell;
          border-width: 1px;
          border-style: solid;
          text-align: center;
          a{
            height: 40px;
            margin:0 auto;
            width:100%;
            display: inline-block;
            line-height: 40px;
          }
        }
      }
    }

    .menu-helper {
      height: 100%;

      & > span {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 500;
      }

      & > ul {
        background: #fff;
        top: calc(50% + 20px);
        font-size: 13px;
      }
    }
  }
}

#header .container .navigation-buttons a[data-target=cart]::before {
  height: 21px;
  width: 22px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='22px' height='21px' viewBox='0 0 22 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='share-photo-1.0-copy-2' transform='translate(-433.000000, -292.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(433.000000, 292.000000)'%3E%3Cpath d='M2,14 L2,2 L0,2 L0,0 L3,0 C3.55229,0 4,0.44772 4,1 L4,13 L16.43842,13 L18.43842,5 L6,5 L6,3 L19.71922,3 C20.27152,3 20.71922,3.44772 20.71922,4 C20.71922,4.08176 20.70922,4.16322 20.68932,4.24254 L18.18932,14.2425 C18.07812,14.6877 17.67812,15 17.21922,15 L3,15 C2.44772,15 2,14.5523 2,14 Z M4,21 C2.89543,21 2,20.1046 2,19 C2,17.8954 2.89543,17 4,17 C5.10457,17 6,17.8954 6,19 C6,20.1046 5.10457,21 4,21 Z M16.00002,21 C14.89542,21 14.00002,20.1046 14.00002,19 C14.00002,17.8954 14.89542,17 16.00002,17 C17.10462,17 18.00002,17.8954 18.00002,19 C18.00002,20.1046 17.10462,21 16.00002,21 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 22px;

  @media (max-width: 479px) {
    margin-top: 9px;
  }

}

.languagesMenu__box[data-target=currency] {
  display: none;
}

.search-input-icon::before {
  height: 22px;
  width: 22px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='22px' height='22px' viewBox='0 0 20.3137 20.3137' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='share-photo-1.0-copy-2' transform='translate(-474.000000, -288.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(474.000000, 288.000000)'%3E%3Cpath d='M9,0 C13.968,0 18,4.032 18,9 C18,13.968 13.968,18 9,18 C4.032,18 0,13.968 0,9 C0,4.032 4.032,0 9,0 Z M9,16 C12.8675,16 16,12.8675 16,9 C16,5.1325 12.8675,2 9,2 C5.1325,2 2,5.1325 2,9 C2,12.8675 5.1325,16 9,16 Z M17.4853,16.0711 L20.3137,18.8995 L18.8995,20.3137 L16.0711,17.4853 L17.4853,16.0711 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 22px;
}

.top-navigation-bar {
  .container {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 991px) {
      width: 100%;
      .quick-contact {
        margin-left: 10px;
      }
    }
  }
}

.top-navigation-tools {
  a {
    white-space: nowrap;
    padding: 4px 15px;
  }

  a[data-testid=headerSignup] {
    display: none;
  }

  .login {
    position: relative;
    font-size: 11px;
    text-transform: uppercase;
    padding: 4px 15px;
    padding-right: 0;
    color: #909095;

    &:hover {
      color: $color-orange;
    }

    &:before {
      height: 16px;
      width: 14px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='17.21347px' height='19.796px' viewBox='0 0 17.21347 19.796' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='share-photo-1.0-copy-2' transform='translate(-691.000000, -202.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(691.000000, 202.000000)'%3E%3Cpath d='M8.60637,15 C12.26877,15 15.47137,16.5751 17.21347,18.9247 L15.37147,19.796 C13.95367,18.1157 11.45377,17 8.60637,17 C5.75893,17 3.25897,18.1157 1.84124,19.796 L4.4408921e-16,18.9238 C1.7422,16.5747 4.94441,15 8.60637,15 Z M8.60637,0 C11.36777,0 13.60637,2.23858 13.60637,5 L13.60637,8 C13.60637,10.6888 11.48407,12.8818 8.82327,12.9954 L8.60637,13 C5.84492,13 3.60634,10.7614 3.60634,8 L3.60634,5 C3.60634,2.31125 5.72865,0.11818 8.38947,0.00462 L8.60637,0 Z M8.60637,2 C7.00867,2 5.70268,3.24892 5.61143,4.82373 L5.60634,5 L5.60634,8 C5.60634,9.6569 6.94947,11 8.60637,11 C10.20407,11 11.50997,9.7511 11.60127,8.1763 L11.60637,8 L11.60637,5 C11.60637,3.34315 10.26317,2 8.60637,2 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: 14px;
      position: absolute;
      left: -6px;
    }
  }
}

#header .site-name a img {
  max-height: 40px;
}

#header {
  .container {
    min-height: 60px;
  }

  .header-bottom {
    border-top: none;
  }
}

.navigation-in > ul > li > a {
  color: #fff;

  &:hover {
    color: $color-orange;
  }
}

#header {
  .header-bottom #navigation .navigation-in > ul > li > a {
    height: 60px;
    line-height: 60px;

    @media (max-width: 1440px) {
      font-size: 15px;
      padding-left: 8px;
      padding-right: 8px;
    }

  }

  @media (min-width: 768px) {
    &.more-rows {
      .header-bottom #navigation .navigation-in > ul > li > a {
        height: 60px;
        max-width: 150px;
        line-height: 18px;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        transition: background-color 0.3s ease-out;

        @media (max-width: 1440px) {
          font-size: 15px;
          padding-left: 8px;
          padding-right: 8px;
        }

      }
    }
  }
}

#header .header-bottom #navigation {
  //height: 60px;
}

.navigation-in ul li a {
  padding-left: 11px;
  padding-right: 11px;
}

.menu-helper > ul > li a b {
  font-weight: 500;
  font-size: 14px;
}

.menu-helper > ul > li a:hover {
  color: $color-orange;
}

#header .search-input {
  border: 1px solid #f4f4f4;
  font-size: 17px;
}

.top-navigation-bar {
  padding: 5px 0;
  font-size: 15px;
}

@media (min-width: 768px) {
  #header .header-top-wrapper .site-name {
    flex: 0 1 25%;
  }
  #header .header-top-wrapper .search {
    flex: 0 1 55%;
  }
  #header .header-top-wrapper .navigation-buttons {
    flex: 0 1 20%;
  }
  #navigation .menu-level-2 {
    border-top: none;
  }
}

#header .header-bottom #navigation .navigation-in > ul > li .menu-level-2 > li {
  margin-bottom: 20px;
}

#header.more-rows .header-bottom #navigation .navigation-in > ul > li:hover > a {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

#header .header-bottom #navigation .navigation-in > ul > li .menu-level-2 .menu-level-3 > li {
  display: block;

  a {
    padding-left: 0;
  }
}

#header .header-bottom #navigation .navigation-in > ul > li .menu-level-2 .menu-level-3 > li:nth-child(n+5) {
  display: none;
}

.navigation-in ul.menu-level-2 a:hover {
  color: $color-orange;

  span {
    text-decoration: none;
  }
}

#navigation .navigation-in ul li:hover > a > span, #navigation .navigation-in ul li:hover > a > b, #navigation .navigation-in ul li:hover > div > a > span {
  color: $color-orange;
  text-decoration: none;
}

#header .header-bottom #navigation .navigation-in > ul > li .menu-level-2 .menu-level-3 > li > a:hover {
  color: $color-orange;
  text-decoration: none;
}

.menu-more {
  display: block !important;

  a {
    color: $color-orange !important;
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .navigation-in > ul > li.ext > ul > li > a.menu-image {
    width: 140px;
    padding-top: 10px;
  }
  .navigation-in > ul > li.ext > ul > li > a img {
    max-height: 70px;
    max-width: unset;
    width: auto;
  }
}

.top-navigation-bar {
  .container {
    .quick-contact {
      flex: 1 1 30%;
      font-size: 13px;
      padding: 4px 15px;
      color: #909095;
      position: relative;

      @include tablet-landscape {
        flex: 1 1 45%;
      }

      a {
        color: $color-orange;

        &:hover {
          color: #000;
        }

        &:before {
          content: '';
          color: #949498;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23949498' d='M9.36556 10.6821C10.302 12.3288 11.6712 13.698 13.3179 14.6344L14.2024 13.3961C14.4965 12.9845 15.0516 12.8573 15.4956 13.0998C16.9024 13.8683 18.4571 14.3353 20.0789 14.4637C20.599 14.5049 21 14.9389 21 15.4606V19.9234C21 20.4361 20.6122 20.8657 20.1022 20.9181C19.5723 20.9726 19.0377 21 18.5 21C9.93959 21 3 14.0604 3 5.5C3 4.96227 3.02742 4.42771 3.08189 3.89776C3.1343 3.38775 3.56394 3 4.07665 3H8.53942C9.0611 3 9.49513 3.40104 9.5363 3.92109C9.66467 5.54288 10.1317 7.09764 10.9002 8.50444C11.1427 8.9484 11.0155 9.50354 10.6039 9.79757L9.36556 10.6821ZM6.84425 10.0252L8.7442 8.66809C8.20547 7.50514 7.83628 6.27183 7.64727 5H5.00907C5.00303 5.16632 5 5.333 5 5.5C5 12.9558 11.0442 19 18.5 19C18.667 19 18.8337 18.997 19 18.9909V16.3527C17.7282 16.1637 16.4949 15.7945 15.3319 15.2558L13.9748 17.1558C13.4258 16.9425 12.8956 16.6915 12.3874 16.4061L12.3293 16.373C10.3697 15.2587 8.74134 13.6303 7.627 11.6707L7.59394 11.6126C7.30849 11.1044 7.05754 10.5742 6.84425 10.0252Z'%3E%3C/path%3E%3C/svg%3E");
          height: 20px;
          width: 20px;
          margin-right: 10px;
          margin-left: 0;
          background-size: 20px;
          position: absolute;
          left: -10px;
        }
      }
    }

    .top-navigation-menu {
      flex: 1 1 60%;
      padding-right: 0;
      display: flex;
      align-items: center;
      font-weight: 700;

      .top-navigation-menu-trigger {
        display: none;
      }

      .top-navigation-bar-menu {
        width: 100%;
        text-align: right;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .color-orange {
        a {
          color: $color-orange;
        }
      }
    }

    .top-navigation-tools {
      flex: 1 1 11%;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 4px 15px;
      color: #909095;
      padding-left: 0;
      justify-content: space-between;

      .dropdown button {
        display: flex;
      }
    }
  }
}

.top-navigation-bar-menu {
  line-height: 1;

  li {
    &:hover {
      text-decoration: none;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.languagesMenu {
  margin: 0 30px 0 10px;

  &__header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.top-navigation-bar-menu li.cropped {
  display: inline-block;
}

.navigation-in ul li a b {
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 1440px) {
    font-size: 13px;
  }
}

#header .header-bottom #navigation .navigation-in > ul > li .menu-level-2 {
  top: 60px;
  max-height: calc(100vh - 140px);
  overflow: scroll;
}

.top-navigation-bar-menu {
  li {
    a {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 4px 15px;
      color: #909095;
      @media (max-width: 991px) {
        padding: 4px 8px;
      }
    }
  }
}

.top-navigation-bar .container .top-navigation-menu .top-navigation-bar-menu a:hover {
  text-decoration: none;
  color: #000;
}

.languagesMenu .languagesMenu__header {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: #909095;

  &:hover {
    color: #000;
  }
}

.user-action .btn.btn-conversion, .user-action .btn.btn-secondary {
  &:hover {
    background-color: #4D9A2B;
  }
}

.login-wrapper .password-helper a {
  &:hover {
    color: $color-orange;
  }
}

.search-window-visible .search-input-icon::before, .search-focused .search-input-icon::before {
  background: none;
  top: 50%;
  position: absolute;
}

.user-action .btn.btn-conversion, .user-action .btn.btn-secondary {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: #4D9A2B;
    border-color: #4D9A2B;
    color: #fff;
  }
}

.popup-widget.login-widget::before {
  right: 40px;
}

.popup-widget.login-widget {
  top: 30px;
}

.admin-logged .popup-widget.login-widget {
  top: 56px;
}

.popup-widget-inner {
  border: none;
}

.popup-widget.cart-widget {
  border: none;
  max-height: 100vh;

  &:before {
    border: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}

.popup-widget.login-widget {
  &:before {
    border: none;
  }
}

.messages {
  z-index: 9999;
}

.cart-widget-bottom {
  display: flex;
  flex-direction: column-reverse;

  .cart-free-shipping > div div {
    padding-bottom: 0;
  }
}

.cart-widget-products {
  .cart-widget-product {
    display: flex;
    align-items: center;

    .cart-widget-product-image {
      order: 1;
    }

    .cart-widget-product-name {
      order: 3;
      width: 65%;

      a {
        text-decoration: none;
      }

      strong {
        color: $color-grey;
      }
    }

    @media (max-width: 767px) {
      .cart-widget-product-unit {
        order: 2;
      }
      }
    .cart-widget-product-amount {
      order: 2;

      .decrease:before,
      .increase:before {
        background-color: $color-light-grey;
      }
    }

    .cart-widget-product-delete {
      order: 5;
      visibility: visible;
    }
  }
}

@media (min-width: 992px) {
  .popup-widget-inner .cart-widget-products {
    padding: 10px 20px;
  }
}

@media (max-width: 767px) {
  #header .header-bottom {
    height: 0;
    overflow: hidden;

    #navigation .navigation-in > ul > li {

      &.appended-category {
        display: none;
      }

      > a {
        color: #000;
        line-height: 30px;

        &[data-testid="signin"] {
          &:before {
            height: 16px;
            width: 14px;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='17.21347px' height='19.796px' viewBox='0 0 17.21347 19.796' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='share-photo-1.0-copy-2' transform='translate(-691.000000, -202.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(691.000000, 202.000000)'%3E%3Cpath d='M8.60637,15 C12.26877,15 15.47137,16.5751 17.21347,18.9247 L15.37147,19.796 C13.95367,18.1157 11.45377,17 8.60637,17 C5.75893,17 3.25897,18.1157 1.84124,19.796 L4.4408921e-16,18.9238 C1.7422,16.5747 4.94441,15 8.60637,15 Z M8.60637,0 C11.36777,0 13.60637,2.23858 13.60637,5 L13.60637,8 C13.60637,10.6888 11.48407,12.8818 8.82327,12.9954 L8.60637,13 C5.84492,13 3.60634,10.7614 3.60634,8 L3.60634,5 C3.60634,2.31125 5.72865,0.11818 8.38947,0.00462 L8.60637,0 Z M8.60637,2 C7.00867,2 5.70268,3.24892 5.61143,4.82373 L5.60634,5 L5.60634,8 C5.60634,9.6569 6.94947,11 8.60637,11 C10.20407,11 11.50997,9.7511 11.60127,8.1763 L11.60637,8 L11.60637,5 C11.60637,3.34315 10.26317,2 8.60637,2 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-size: 14px;
            position: absolute;
            right: 20px;
          }
        }

        b {
          text-decoration: none;
        }

        span {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 13px;
        }

        &:hover {
          color: $color-orange;
        }
      }

      .menu-level-2 .menu-level-3 > li a {
        padding-left: 90px;
      }
    }
  }
}

