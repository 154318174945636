.lang-switcher {
    position: relative;
    padding: 4px 6px;
    margin-right: 42px;
    margin-left: 10px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -6px;
        width: 0;
        height: 0;
        margin-top: -3px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $color-orange;
    }

    &:hover {
        .lang-switcher__list {
            display: block;
        }
    }

    &__current {
        cursor: default;
    }

    &__list {
        display: none;
        position: absolute;
        top: 0;
        left: -16px;
        padding-top: 22px;

        &:hover{
            display: block;
        }

        ul {
            position: relative;
            padding: 10px 0;
            list-style: none;
            background-color: #fff;
            box-shadow: 0 10px 50px rgba(1, 2, 2, .15);

            &:before {
                width: 0;
                height: 0;
                background: 0;
                border-style: solid;
                content: '';
                display: inline-block;
                border-width: 0 9px 9px;
                border-color: transparent transparent #fff;
                position: absolute;
                left: 50%;
                margin-left: -9px;
                bottom: 100%;
            }
        }

        li {
            padding-left: 0;
        }

        a {
            color: #24282b;
            font-weight: 400;
            font-size: 12px;
            text-decoration: none;
            display: block;
            padding: 6px 20px 6px 18px;
            border-left: 2px solid #fff;

            &:hover {
                border-left-color: $color-orange;
                background-color: #f9f9f9;
            }
        }
    }
}