/*
BANNERY
*/
.desktop .banners-row {
  margin-top: 0;
}

#pp-banners {
  display: grid;
  padding: 15px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  #carousel {
    margin: 0;
    grid-column: span 2;
    grid-row: span 2;
    height: 100% !important;
    min-height: auto !important;

    .carousel-inner {
      min-height: 600px !important;
    }

    .item {
      min-height: 600px !important;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        min-height: 600px;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    .banner-wrapper {
      &:nth-child(4), &:nth-child(5) {
        height: 400px;
      }
    }
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(5, 1fr);
    .banner-wrapper {
      height: auto !important;
    }
  }
  @media (max-width: 991px) {
    #carousel {
      .carousel-inner {
        min-height: auto !important;
        .item {
          min-height: auto!important;
          img {
            min-height: auto;
          }
        }
      }
    }

  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    .banner-wrapper {
      &:nth-child(2), &:nth-child(3), &:nth-child(5) {
        height: 200px;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media (max-width: 480px) {
    //display: inline-block;
    .banner-wrapper {
      &:nth-child(2), &:nth-child(3), &:nth-child(5) {
        height: 200px;
        margin-top: 15px;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  .banner-wrapper {
    margin: 0;

    a {
      width: 100%;
      height: 100%;

    }

    .pp-img {
      width: 100%;
      height: 100%;
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }


    &:nth-child(4) {
      grid-column: span 1;
      grid-row: span 2;
    }

    &:nth-child(5) {
      grid-column: span 2;
      grid-row: span 1;

    }
  }
}

#pp-banners #carousel .item {
  /*   &:after {
       content: '';
       inset: 0;
       position: absolute;
       height: 100%;
       width: 100%;
       z-index: 10;
       background-color: rgba(51, 51, 51, .7);
     }*/
  img {
    transition: all .2s;
  }

  .extended-banner-texts {
    z-index: 9;
    margin-left: 20px;
    margin-bottom: 20px;

    .extended-banner-text {
      background-color: rgba(51, 51, 51, .8);
      padding: 10px 20px;
      font-size: 32px;
      @include desktopxl {
        font-size: 24px;
      }
    }

    .extended-banner-link {
      position: absolute;
      top: -24px;
      left: 30px;
      padding: 5px 10px;
      background-color: $color-orange;
      font-size: 15px;
      margin-top: 0;
    }

    .extended-banner-link-clanek {
      background-color: $color-green;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    .extended-banner-texts {
      .extended-banner-title {
        color: $color-orange;
      }

      .extended-banner-text {
        color: $color-orange;
      }
    }
  }
}

#pp-banners .banner-wrapper {
  /*  &:after {
      content: '';
      inset: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
      background-color: rgba(51, 51, 51, .7);
    }*/
  position: relative;

  .extended-banner-texts {
    z-index: 15;
    margin-left: 20px;
    margin-bottom: 20px;

    .extended-banner-text {
      background-color: rgba(51, 51, 51, .8);
      padding: 10px 20px;
      @include desktopxl {
        padding: 8px 15px;
      }
    }

    .extended-banner-link {
      position: absolute;
      top: -24px;
      left: 30px;
      padding: 5px 10px;
      background-color: $color-orange;
      font-size: 15px;
      @include desktopxl {
        left: 20px;
        font-size: 13px;
      }
    }

    .extended-banner-link-clanek {
      background-color: $color-green;
    }
  }
}

.extended-banner-text {
  font-size: 32px;
  @include desktopxl {
    font-size: 24px;
  }
}

#pp-banners .banner-wrapper a {
  overflow: hidden;

  .pp-img {
    transition: all .2s;
  }

  &:hover {
    .pp-img {
      transform: scale(1.05);
    }

    .extended-banner-title {
      color: $color-orange;
    }

    .extended-banner-text {
      color: $color-orange;
    }
  }
}

.carousel-control {
  color: $color-orange;
  opacity: 0.9;

  &:before {
    border: none;
    font-size: 40px;
  }

  &.right {
    &:before {
      height: 42px;
      width: 28px;
      content: '';
      background-size: 100%;
      border-radius: 0;
      //background-image: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23909095' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='5.65684px' height='8.48529px' viewBox='0 0 5.65684 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-317.343260, -237.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='arrow-drop-right-line' transform='translate(317.343260, 237.000000)'%3E%3Cpolygon id='Path' points='2.82844 4.24269 0 1.41422 1.41424 0 5.65684 4.24269 1.41424 8.48529 0 7.07109'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  &.left {
    &:before {
      height: 42px;
      width: 28px;
      content: '';
      background-size: 100%;
      border-radius: 0;
      //background-image: url("data:image/svg+xml,%3Csvg width='5.6569px' height='8.48529px' viewBox='0 0 5.6569 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-286.000000, -237.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='arrow-drop-left-line' transform='translate(286.000000, 237.000000)'%3E%3Cpolygon id='Path' points='2.8284 4.24269 5.6569 7.07109 4.2426 8.48529 0 4.24269 4.2426 0 5.6569 1.41422'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%3Csvg width='5.6569px' height='8.48529px' viewBox='0 0 5.6569 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-286.000000, -237.000000)' fill='%23909095' fill-rule='nonzero'%3E%3Cg id='arrow-drop-left-line' transform='translate(286.000000, 237.000000)'%3E%3Cpolygon id='Path' points='2.8284 4.24269 5.6569 7.07109 4.2426 8.48529 0 4.24269 4.2426 0 5.6569 1.41422'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='5.6569px' height='8.48529px' viewBox='0 0 5.6569 8.48529' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Bitmap-Copy-48' transform='translate(-286.000000, -237.000000)' fill='%23FF6000' fill-rule='nonzero'%3E%3Cg id='arrow-drop-left-line' transform='translate(286.000000, 237.000000)'%3E%3Cpolygon id='Path' points='2.8284 4.24269 5.6569 7.07109 4.2426 8.48529 0 4.24269 4.2426 0 5.6569 1.41422'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }
}

.banners-content {
  .extended-banner-title {
    color: #fff;
  }

  .extended-banner-text {
    color: #fff;
  }

  a {
    &:hover {
      .extended-banner-title {
        color: $color-orange;
      }

      .extended-banner-text {
        color: $color-orange;
      }
    }
  }
}

.footer-banner {
  .extended-banner-text {
    font-size: 24px;
    line-height: 1;
  }

  a {
    &:hover {
      .extended-banner-text {
        color: $color-orange;
      }
    }
  }
}

[class*="custom-footer__banner"] {
  display: none;
}

.type-index [class*="custom-footer__banner"] {
  display: block;
  @include tablet-portrait {
    &:nth-of-type(1n+6){ display:none; }
  }
}

#pp-banners {
  background-color: #fff;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.extended-banner-text {
  font-weight: 400;
}
#pp-banners {
  @include tablet-landscape {
    .banner-wrapper {
      display: none;
    }
    grid-template-columns: repeat(1, 1fr) !important;
    max-height: 33vh;
    min-height: 200px;
    padding-top: 0;
    #carousel .carousel-inner {
      .item img {
        max-height: 33vh;
        min-height: 200px;
        object-fit: cover;
      }
    }
  }
}