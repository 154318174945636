#able-chart {
  padding: 50px;
  width: 100%;

  @media (max-width: 991px) {
    padding: 20px;
  }

  h4 {
    text-align: center;
    font-size: 21px;
    font-weight: 400 !important;
    margin-bottom: 30px;
  }

  .chart-options {
    background: #f2f2f2;
    padding: 10px 30px;
    margin-bottom: 30px;
    text-align: left;

    div {
      display: inline-block;

      &:nth-of-type(2) {
        float: right;
        text-align: right;
      }
    }

    span {
      padding: 0;
      margin: 0 15px 0 0;
      cursor: pointer;

      &:not(.label):not(.today):not(.active):hover {
        text-decoration: underline;
      }

      &.active {
        color: #FF8033;

        &:hover {
          text-decoration: none;
        }
      }

      &.today {
        text-align: right;

        &:before {
          display: inline-block;
          content: '';
          -webkit-border-radius: 50%;
          border-radius: 50%;
          height: 10px;
          width: 10px;
          margin-right: 0.5rem;
          background-color: #000;
        }

      }
    }
  }

  @media (max-width: 750px) {
    .chart-options {
      padding: 10px;

      span {
        margin-right: 10px;
        font-size: 13px;
      }
    }
  }
  @media (max-width: 600px) {
    .chart-options {
      div {
        display: block;
        float: none !important;
      }

    }
  }

  #ableChart {
    width: 100% !important;
    margin: 0 auto;
  }
}

.able_banner_chart {
  flex-basis: 100%;
  margin-bottom: 20px;
  .able_banner_inner {
    background-image: url('../../images/graf_vyvoj.jpg');
    height: 100px;
    background-size: cover;
    width: 333px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      transition: all 0.3s ease-out;
    }

    button:hover {
      color: #000;
      background: #FF8033;
    }
  }
}
