body.type-page,
body.type-post,
body.type-posts-listing {
    h1 {
        font-size: 38px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 50px;
    }

    .content {
        .content-inner {
            article {
                div[itemprop="about"], div.text {
                    background: #fff;
                    padding: 30px;
                }
            }
        }
    }
}

.type-post {
    article {
        .text {
            p,
            li {
                font-family: "Montserrat", Arial, sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 2;
                color: #909095;
                margin: 0 0 15px;
            }

            strong {
                font-weight: 400;
            }
        }

    }
}

.type-posts-listing {
    .content-inner {
        p,
        li {
            font-family: "Montserrat", Arial, sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 2;
            color: #909095;
            margin: 0 0 15px;
        }

        strong {
            font-weight: 400;
        }
    }
}

// Contact
.contact {
    &__title {
        color: #24282b;
        font-weight: 300;
        font-size: 24px;
        line-height: 1.67;
        margin-bottom: 20px;

        &--small {
            font-weight: 400;
            font-size: 15px;
            line-height: 2;
            color: #24282b;
        }
    }

    strong {
        font-weight: 500;
    }

    .contact-top {
        border-bottom: 3px solid #efeff2;
        padding-bottom: 50px;

        p {
            line-height: 1.5;
            margin-bottom: 0 !important;
        }

        &__highlight {
            padding: 12px 0;
        }

        &__text {
            font-weight: 400;
            font-size: 13px;
            line-height: 1.85;
            color: #909095;
            padding-bottom: 12px;
        }

        &__small {
            font-weight: 400;
            font-size: 13px;
            line-height: 1.85;
            color: #909095;
            padding-bottom: 12px;
        }

        a {
            margin: 20px 0;
            font-weight: 500;
            display: inline-block;
        }
    }

    .contact-middle {
        border-bottom: 3px solid #efeff2;
        padding-bottom: 50px;

    }

    .contact-bottom {
        padding-bottom: 50px;

        &__item {
            padding-bottom: 50px;
            min-height: 160px;
        }
    }

    &__webs {
        a {
            &:first-child {
                margin-right: 30px;
            }
        }
    }

    &__position {
        line-height: 1.33;
        margin-bottom: 6px;
        font-size: 15px;
        min-height: 38px;
        color: #24282b;
        font-weight: 400;

        &--small {
            font-size: 13px;
            color: #75757a;
            display: block;
        }
    }

    &__name {
        line-height: 1.33;
        font-size: 15px;
        color: #24282b;
        font-weight: 500;
        margin-bottom: 10px;
    }

    &__email {

    }
}

.contact-footer {
    padding: 20px 50px;
}

// 404
.id-404 {
    .content-inner {
        header {
            h1 {
                display: none;
            }
        }
    }

    .breadcrumbs {
        display: none;
    }
}

.error-404 {
    max-height: 850px;

    &:before {
        content: "";
        background: url(https://550310.myshoptet.com/user/documents/images/bg-404.jpg) center top no-repeat;
        background-size: contain;
        display: block;
        margin-bottom: -10%;
        padding-bottom: 57%;
    }

    &__content {
        text-align: center;

        p {
            margin: 30px 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 2;
            color: #909095;
        }
    }

    h1 {
        font-weight: 300;
        font-size: 36px;
        line-height: 1.22;
    }

    &__button {
        text-align: center;

        a {
            background: #69c63e;
            border-color: #69c63e;
            color: #fff;
            font-weight: 500;
            display: inline-block;
            padding: 10px 26px;
            border-style: solid;
            border-width: 1px;
            border-radius: 0;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            text-decoration: none;
            white-space: normal;
            -webkit-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            @include animation();

            &:hover {
                background-color: $color-green-hover;
                border-color: $color-green-hover;
                box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
            }
        }
    }
}

body.type-page .content .content-inner article div[itemprop=about],
body.type-page .content .content-inner article div.text,
body.type-post .content .content-inner article div[itemprop=about],
body.type-post .content .content-inner article div.text,
body.type-posts-listing .content .content-inner {
    padding: 30px 0;
}

.type-page {
    .sidebar {
        display: none;
    }
}

.id--4,
.id--7,
.id--8,
.in-login,
.in-zapomenute-heslo {
    .content-inner {
        max-width: 600px;
        margin: 0 auto;

        h1 {
            text-align: center;
        }
    }

    .sidebar {
        display: none;
    }

    .content-wrapper {
        form {
            padding: 30px;
            @include tablet-portrait {
                padding: 15px;
            }
        }
    }

    #additionalInformation {
        width: 100%;
    }
}


body.type-posts-listing {
    .content-inner {
        max-width: 900px;
        margin: 0 auto;
    }
}

.type-post {
    .breadcrumbs {
        #navigation-1 {
            display: none;
        }
    }

    .text {
        max-width: 900px;
        margin: 0 auto;
    }
}

.in-nase-novinky,
.in-kontakty-2 {
    .text {
        max-width: unset;
        margin: 0 auto;
    }
}