.pagination-wrapper {
  position: relative;
  z-index: 5;
  margin-top: 130px;

  .pagination-top {
    strong {
      text-transform: capitalize;
      border: 1px solid $color-orange;
      border-left: none;
      background-color: transparent;
      font-size: 14px;
    }

    .pagination-link {
      border: 1px solid $color-orange;
      border-right: none;
      background-color: transparent;
      font-size: 14px;
      color: $color-orange;
    }
  }

}

#loadNextSearchResults, .pagination-loader .load-products {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border-radius: 0;
  outline: 0;
  white-space: nowrap;
  background-color: $color-orange;
  color: #fff;
  border: 1px solid $color-orange;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: $color-orange;
    background-color: transparent;
  }
}

