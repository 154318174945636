body.ordering-process {

  ol.cart-header li.active a::before, ol.cart-header li.active strong::before {
    background: var(--color-primary);
    border-color: var(--color-primary);
  }

  ol.cart-header li.completed a::before, ol.cart-header li.completed strong::before {
    background: var(--color-primary-hover);
    border-color: #cfcfcf;
  }

  .radio-wrapper.active label > span b {
    color: var(--color-primary);
  }

  ol.cart-header li.active a, ol.cart-header li.completed a, ol.cart-header li.active strong, ol.cart-header li.completed strong {
    background: transparent;
  }

  ol.cart-header .completed a span {
    text-decoration: none;
  }

  .cart-inner {
    background: #fff;
    padding: 30px;

    .btn.btn-conversion, .btn.btn-secondary {
      background: #69c63e;
      border-color: #69c63e;
      color: #fff;
      font-weight: 700;

      &:hover {
        background: #3f7a24;
        border-color: #3f7a24;
      }
    }
  }
}

.cart-free-shipping strong {
  padding-top: 0 !important;
  color: $color-orange;
}

.cart-widget .cart-free-shipping {
  padding: 20px;
  margin: 10px 0 0 0;
  text-align: center;
  font-size: 14px;
  background-color: #f7f7f7;
}

.cart-widget-products .cart-widget-product:last-child {
  border-color: transparent;
}

.box.box-sm.box-filters {
  border: none;
  background-color: #f7f7f7;
}

.box.box-sm.box-filters .filter-sections {
  background-color: #f7f7f7;
}

.ordering-process {
  #header .container {
    min-height: 50px;
  }
}

.ordering-process {
  .cart-inner {
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  }

  .checkout-box {
    .btn.btn-default:hover, a.btn.btn-default:hover {
      color: $color-orange;
    }
  }
}

.id--9 .header-bottom, .id--16 .header-bottom, .id--17 .header-bottom {
  height: 0;
}

.id--9 .header-bottom #navigation, .id--16 .header-bottom #navigation {
  display: none;
}

.id--9 #formSearchForm, .id--16 #formSearchForm, .id--17 #formSearchForm {
  display: block;
}

.cart-inner {
  padding: 0 30px;

  .cart-table-header {
    display: none;
  }

  tr {
    &:nth-child(2) {
      td {
        border-top: none;
      }
    }

    td {
      &.p-name {
        a {
          &:hover {
            color: initial;
          }
        }
      }
    }
  }
}

.order-summary {
  .checkout-box {
    display: none;
  }

  .order-summary-item .cart-item-price {
    width: 100%;
    text-align: left;
    margin-top: 5px;
    color: $color-grey;
  }

  .order-summary-item .cart-item-name {
    a {
      color: #24282b;
      font-size: 16px;
    }
  }
}

#checkoutSidebar {
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-top: 30px;
    &:before {
      content: " ";
      border-top: 3px solid #ccc;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    #summary-box {
      border-top: 0;

      h4 {
        display: block;
        border-bottom: 1px solid #cfcfcf;
      }
    }
  }
}

.basket-Help {
  background-repeat: no-repeat;
  background-image: url('../../images/svg/phone.svg');
  min-height: 60px;
  padding-left: 80px;
  padding-top: 6px;


  .basket-Help-heading {
    margin: 0 0 6px;
    font-size: 16px;
    color: #909095;
    font-weight: 400;
  }

  .basket-Help-action {
    font-size: 15px;
    color: #24282b;
    text-decoration: none;
  }
}

.extra.delivery {
  color: var(--color-primary);
}


// děkovací stránka /objednavka/dekujeme/
.id--15 {
  .order-payment,
  .order-content {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 24px auto;

    &:before {
      display: none !important;
    }
  }

  .order-payment {
    background-image: url('../../images/svg/platba.svg');
  }

  .order-content {
    background-image: url('../../images/svg/obsah_objednavky.svg');
  }


  .content-inner > *:not(div) {
    display: none;
  }

  /*&.ordering-process .btn.btn-conversion{
      background-color: #fff !important;
      border-color: var(--color-primary) !important;
      color: var(--color-primary) !important;
      font-weight: 500;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 16px;
  }*/

  .content-order-row {
    @media (min-width: 768px) {
      display: flex;

      .content-order-left,
      .co-payment-method {
        width: 50%;
      }

      .order-payment {
        margin-top: 0 !important;
      }

    }

    .recapitulation-table {
      width: 100%;
      text-align: left;
    }

    .order-payment {
      font-size: 30px;
      margin-bottom: 28px;
    }
  }
}

// prázdný košík, odebrat vyhledávání
.id--9 .empty-cart-boxes .col-md-6:nth-child(2) {
  display: none;
}

// vypnout scrollání v košíku
@media (min-width: 992px) {
  .popup-widget-inner .cart-widget-products {
    max-height: calc(100vh - 350px);
  }
}

.decrease,
.increase {
  &:before {
    background-color: $color-light-grey;
  }
}

// zobrazit fade v košíku pokud je scroll
.cart-widget-bottom {
  &.is-more {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 50px;
      right: 0;
      top: -50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #9b9b9b);
      transition: all 0.3s linear;
    }
  }
}


//bold v rekapitulaci
.order-summary-inner {
  h4 {
    font-weight: 700 !important;
  }
}

//šedé číslo v rekapitulaci
.cart-item-amount {
  color: #aaa;
}

@media (max-width: 480px) {
  .cart-widget-inner.popup-widget-inner .cart-widget-products.noFree {
    height: calc(100% - 93px);
  }
}

@media (max-width: 991px) {
  .cart-widget-inner.popup-widget-inner .cart-widget-products.noFree {
    height: calc(100% - 93px);
  }
}

@media (max-width: 480px) {
  .cart-widget-inner.popup-widget-inner .cart-widget-products {
    .cart-widget-product-image {
      width: auto;
    }

    .cart-widget-product-unit {
      padding-right: 0;
      display: none;
    }

    .cart-widget-product-name {
      width: 45%;
      font-size: 13px;
      margin-bottom: 0;
    }

    .cart-widget-product-amount {
      width: auto;

      .quantity {
        padding-left: 20px;
        padding-right: 20px;

        .increase, .decrease {
          width: 20px;
        }
      }
    }
  }
}


tr.related{
  td{
    background-color: #f7f7f7 !important;
    border-color: #cfcfcf;
    border-style: solid;
    border-width: 1px 0;
  }
}

.cart-related-product {
  margin: 0 auto;


  .cart-related-img {
    max-width: 55px;
    padding: 5px;
    background-color: #fff;
    border:1px solid #cfcfcf
  }

  .add-to-cart-button {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px !important;
  }

  @media (max-width: 1200px) {
    max-width: 700px;
    .cart-related-img {
      max-width: 40px;
    }
    .add-to-cart-button {
      height: 30px !important;
      line-height: 30px !important;
      font-size: 15px !important;
    }
  }
  @media (max-width: 991px) {
    max-width: 600px;
    .cart-related-info {
      width: 60% !important;
    }
    .cart-related-button {
      width: 40% !important;
      flex-flow: row !important;

      .price-final {
        margin-bottom: 0;
      }
    }
    .cart-related-img {
      max-width: 40px;
    }
    .add-to-cart-button {
      height: 30px !important;
      line-height: 30px !important;
      font-size: 15px !important;
    }
  }

  @media (max-width: 768px) {
    max-width: 600px;
    .cart-related-info {
      width: 60% !important;
    }
    .cart-related-button {
      width: 40% !important;
      flex-flow: column !important;

      .price-final {
        margin-bottom: 0;
      }
    }
    .cart-related-img {
      margin-right: 10px;
    }
    .add-to-cart-button {
      height: 30px !important;
      line-height: 30px !important;
      font-size: 15px !important;
    }
  }
}

.cart-table {

  .show-related{
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    &:after{
      font-size: 17px;
    }
  }
  /*.related-visible .show-related{
    text-decoration: underline;
  }*/


  .related-prods{
    display: none;
  }
  @media (max-width: 991px) {

    tr.related-visible{
      margin-bottom: 0;
      padding-bottom: 0;
    }

    tr.related.visible{
      display:block;
      margin-bottom: 10px;
    }
    tr{
      border-bottom:0 !important;
    }

    .related-prods{
      display: flex;
      width: 100%;
      order: 10;
      padding: 10px 5px;
      text-align: center;
      background-color: #e7e7e7;


      .show-related{
        display:inline-block;
        width:100%;
        text-align: center;
        padding-top: 0;
        color: #1a1937;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .p-name {
      display: flex;
      flex-flow: column;

      .availability-label{
        margin-left: 0 !important;
      }
      .show-related {
        display: none !important;
        order:3;
      }
    }
  }
}