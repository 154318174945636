#colorbox {
  width: 100% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  #cboxWrapper, #cboxContent, #cboxLoadedContent {
    width: 100% !important;
    max-width: 1200px !important;
  }
  #cboxWrapper {
    left: 50%;
    transform: translateX(-50%);
  }
  #cboxClose {
    background-color: $color-orange !important;
    @include animation();
    &:before {
      color: #fff;
      font-size: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      background-color: #f76d11 !important;
    }
  }
  .content-modal {
    padding: 50px 100px;
    textarea, input {
      font-size: 13px;
      background: #fff;
      border: 1px solid #dddde1;
      padding: 0 20px;
      border-radius: 1px;
      font-weight: 400;
      display: block;
      width: 100%;
      color: #75757a;
      outline: 0;
      -webkit-appearance: none;
      &:focus {
        box-shadow: 0 5px 15px rgba(1,2,2,.05);
      }
    }
    input {
      height: 50px;
      line-height: 50px;
    }
    .submit-wrapper {
      input {
        background: #69c63e;
        border-color: #69c63e;
        color: #fff;
        width: auto;
        font-weight: 500;
        display: inline-block;
        padding: 10px 26px;
        border-style: solid;
        border-width: 1px;
        border-radius: 0;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        white-space: normal;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        @include animation();
        &:hover {
          border-color: $color-green-hover;
          background: $color-green-hover;
          box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
}