.benefitBanner {
    padding: 20px 50px;
    @include tablet-portrait {
        padding: 20px;
    }


    &__content {
        display: flex;
        word-break: normal;
        align-items: center;

        @include phone {
            flex-direction: column;
            text-align: center;
        }
    }

    .benefitBanner__item {
        justify-content: center;
        @media (max-width: 991px) {
            flex: 100%;
        }
        @include phone {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    .benefitBanner__title {
        color: $color-black;
        font-size: 36px;
        width: auto;
        padding-right: 20px;
        font-weight: 400;
        @include phone {
            padding-right: 0;
        }
    }

    .benefitBanner__data {
        font-size: 16px;
        color: #909095;
    }

    .benefitBanner__picture {
        flex: 0 0 60px;
        max-width: 60px;
        margin-right: 20px;
        @include phone {
            margin-right: 0;
        }

        img {
            height: 60px;
        }
    }
}