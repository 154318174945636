/* Hlavička sticky */
@media screen and (max-width: 767px) {
  body.pp-sticky {
    #header .cart-count {
      //top: -46px;
    }

    &:not(.ordering-process) #header{
      //position: fixed !important;
      width: 100%;
      top: 0;
      z-index: 53;
      border-bottom: 2px solid #ededed;

      .navigation-buttons{
        a{
          height: 60px !important;
          width:60px !important;
          &:before {
            line-height: 60px;
          }
        }
      }

    }

  }
  .scrolled-down body:not(.ordering-process):not(.search-window-visible) #header{
    transform: none;
  }
  .scrolled-up body:not(.ordering-process):not(.search-window-visible){
    #header{
      position: fixed;
    }
    .overall-wrapper{
      margin-top: 62px;
    }
  }

  .navigation-window-visible.pp-sticky:not(.ordering-process):not(.paxio-merkur) .top-navigation-bar {
    z-index: 9999;
  }
  .navigation-window-visible.pp-sticky:not(.ordering-process):not(.paxio-merkur) .top-navigation-bar .top-navigation-tools, .navigation-window-visible.pp-sticky:not(.ordering-process):not(.paxio-merkur) .top-navigation-bar .site-name {
    display: none;
  }
  .pp-sticky:not(.ordering-process):not(.paxio-merkur) .cart-count {
    /*position: fixed !important;
    top: 18px !important;
    z-index: 10;*/
    height: 60px;
    width:60px;
  }
  .pp-sticky:not(.ordering-process):not(.paxio-merkur) #content-wrapper {
    padding-top: 6px;
  }
  .cart-count {
    transition: none !important;
  }
  body:not(.pp-sticky):not(.paxio-merkur) .top-navigation-bar .site-name {
    display: none;
  }
  body:not(.paxio-merkur) .top-navigation-bar .site-name {
    position: absolute;
    top: 10px;
    left: 20px;
    margin: 0 !important;
    max-width: 50%;
    display: flex;
    align-items: center;
    height: 45px;
  }
  body:not(.paxio-merkur) .top-navigation-bar .site-name img {
    max-height: 45px;
  }
  .pp-sticky:not(.ordering-process):not(.paxio-merkur) .responsive-tools > a[data-target="login"], .pp-sticky .responsive-tools > a[data-target="search"] {
    visibility: hidden;
    transition: none;
  }
}

@media screen and (min-width: 768px) {
  .pp-sticky:not(.ordering-process) #header {
    position: sticky;
    position: -webkit-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    top: 0;
    z-index: 1003;
    background: #fff;
    -webkit-box-shadow: 0px 2px 10px 0px rgb(170, 170, 170);
    -moz-box-shadow: 0px 2px 10px 0px rgb(170, 170, 170);
    box-shadow: 0px 2px 10px 0px rgb(170, 170, 170);
  }
  .pp-sticky:not(.ordering-process) #header .site-name img {
    max-height: 58px;
  }
  .pp-sticky:not(.ordering-process) #header .header-top {
    padding: 0;
    height: 58px;
  }
  .pp-sticky:not(.ordering-process) #content-wrapper.content-wrapper {
    padding-top: 30px;
  }
  .pp-sticky:not(.ordering-process) .menu-helper {
    top: 70px;
  }
  .pp-sticky:not(.ordering-process) #content-wrapper.content-wrapper {
    //padding-top: 70px;
  }
  .pp-sticky.type-index .overall-wrapper {
    padding-top: 33px;
  }
  .pp-sticky .breadcrumbs-wrapper {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .pp-sticky .popup-widget.cart-widget {
    position: fixed;
    top: 51px;
  }
}
@media screen and (min-width: 768px) {
  .pp-sticky:not(.ordering-process) .menu-helper {
    top: 0;
  }
  .menu-helper {
    & > span {
      color: #fff;
    }

    &:hover {
      background-color: #fff;

      & > span {
        color: $color-orange;
      }
    }
  }
}
.pp-sticky:not(.ordering-process) #header .site-name img {
  max-height: 34px;
}
/* END - Hlavička sticky */