* {
    font-family: var(--template-font) !important;
    letter-spacing:0 !important;
}

.type-category #content {
    opacity: 0;
}

body .top-navigation-bar .container, #pp-banners, .row.banners-row:not(.footer-banners) {
    opacity: 0
}

body.loaded .top-navigation-bar .container {
    opacity: 1
}

body.loaded #pp-banners {
    opacity: 1
}

.products-block > div .p .name {
    height: 100%;
    display: flex;
    justify-content: center;
}

.products-block .product .p .flags-default {
    top: -15px;
}

.type-category .subcategories:before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 0;
    left: -9998px;
    right: 0;
    border-left: 9999px solid #f7f7f7;
    box-shadow: 9999px 0 0 #f7f7f7;
    display: flex;
}

@media (min-width: 767px) {
    .type-category .category-content-wrapper:before {
        content: "";
        position: absolute;
        bottom: 0px;
        top: 0;
        left: -9998px;
        right: 0;
        border-left: 9999px solid #fff;
        box-shadow: 9999px 0 0 #fff;
        display: flex;
    }

}

.p-data-wrapper {
    @media (min-width: 1200px) {
        width: 40% !important;
    }
}

.p-image-wrapper {
    @media (min-width: 1200px) {
        width: 60% !important;
    }
}

.p-final-price-wrapper .price-additional {
    color: #aaa;
    margin-left: 20px;
    margin-top: 2px;
}

.brand-wrapper span {
    width: 205px;
}

.slick-list {
    max-height: 500px;
}