/*
Obecné styly pro malý produkt - kategorie + sliders
*/
.products-block .product {
    width: 100%;
    margin-bottom: 0;
    position: relative;
    padding: 10px;

    .p {
        padding: 10px;
        background: #fff;

        &:hover {
            box-shadow: none;

            a {
                text-decoration: none;
            }
        }

        .image {
            padding-bottom: 40px;
            .flags{
                display:none;
            }
        }

        .flags-default,
        .flags-extra {
            width: 100%;
            display: inline-flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            margin: 15px auto 0;
            top: auto;
            left: 0;
            bottom: 0;

            .flag {
                padding: 7px;
                max-width: none;
            }
        }

        .p-code {
            position: relative;
            order: -1;
            text-align: right;
            top: 0;
            height: 20px;
            display: block;
            visibility: hidden;
        }

        .p-in {
            .p-bottom > div {

                .prices {
                    * {
                        font-size: 15px;
                        margin: 0;
                    }

                    .price-standard {
                        order: 1;
                        margin-right: 10px;

                        strong {
                            font-weight: 300;
                            font-size: 13px;
                        }
                    }

                    .price-final {
                        order: 3;
                        flex-basis: auto !important;
                        margin-top: 5px;
                    }

                    .price-save {
                        order: 2;
                        font-size: 13px;
                        margin-right: 10px;
                    }
                }

                .p-tools {

                    .quantity {
                        height: 36px;
                        border-color: #f7f7f7;

                        input {
                            width: 30px;
                            height: 100%;
                        }

                        .increase, .decrease {

                            height: 34px;

                            &:before {
                                height: 36px;
                                line-height: 36px;
                            }
                        }
                    }

                    .btn-cart, .btn-primary {
                        background: #69c63e;
                        border-color: #69c63e;
                        color: #fff;
                        font-weight: 700;
                        padding: 5px;
                        font-size: 14px;
                        line-height: inherit;
                    }

                    .btn-cart {
                        background: var(--color-tertiary-hover);
                        border-color: var(--color-tertiary-hover);
                    }
                }
            }
        }


    }

    @media (min-width: 768px) {

        &:hover {
            box-shadow: none;

            .p {
                box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.36);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;

                .p-code, .p-in .p-bottom > div .p-tools {
                    display: inline-block;
                    visibility: visible;
                }

            }
        }
    }
    @media (max-width: 768px) or (hover: none) {
        .p {
            box-shadow: none;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;

            .p-code, .p-in .p-bottom > div .p-tools {
                display: inline-block;
                visibility: visible;
                opacity: 1;
                transition: none;
            }

        }
    }
}

.product-slider-holder.has-navigation {
    padding-left: 20px;
    padding-right: 20px;
}

.products-block .product {
    padding: 10px 20px;
}

.p:hover .flag {
    opacity: 1;
}

.products-block .product .p {
    .flags {
        position: relative;
        flex-wrap: wrap;
    }
}

.flags {
    .flag-tip {
        background-color: #B94701;
    }

    .flag-action {
        background-color: #ff6000;
    }

    .flag-new {
        background-color: #353639;
    }

    .flag-custom1 {
        background-color: #D8D8D8;
    }

    .flag-freeshipping {
        background-color: #f8a102;
    }
}

.products-block .product .p .image {
    padding-bottom: 0;
    //min-height: 265px;
}

.products-block .product .p .p-in .p-bottom > div .p-tools .quantity {
    max-width: 100px;
    margin: 0 auto;
}

.products-block > div .p .p-bottom > div .p-tools .pr-action {
    display: flex;
    gap: 10px;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .products-block .quantity {
        display: block;
    }
}

.products-block > div .p .p-bottom > div .p-tools .btn, .products-block > div .p .p-bottom > div .p-tools .btn.btn-cart {
    font-size: 16px;
    background-color: #69c63e;
}

.products-block .product .p .p-in .p-bottom > div .p-tools .btn-cart {
    background-color: #69c63e;
    border-color: #69c63e;
    width: 100%;
    max-width: 150px;

    &:hover {
        background-color: #4D9A2B;
    }
}

.products-block > div .p .stars-placeholder {
    display: none;
}

.products-block > div .p .p-bottom {
    padding: 0 10px 10px;
}

.products-block > div .p .name {
    min-height: 46px;
    max-height: 66px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
}

.products-block > div .p .p-bottom > div .p-tools {
    //transition: all .4s;
    visibility: hidden;
    opacity: 0;
    position: relative;
    bottom: -100%;
    left: 0;
    display: none;

}

.products-block .product:hover .p .p-code, .products-block .product:hover .p .p-in .p-bottom > div .p-tools {
    position: relative;
    visibility: visible;
    opacity: 1;
    bottom: 0;
    display: block;
    left: 0;
}

.products-block .product:hover p {
    //box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
}

.products-block .product.isLarge:hover .p {
    position: relative;
}

.product {
    &.isLarge {
        .p-in {
            @media (min-width: 768px) {
                .name {
                    font-size: 32px;
                }

                .availability {
                    font-size: 20px;
                }

                .prices {
                    font-size: 20px;
                }
            }
        }
    }
}


.search-next-wrap{
    margin-top:130px;
}